import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { has as _has } from 'lodash';
import { useEffect, useState } from 'react';
import Endpoints from '../../../../api/endpoints/endpoints';
import { AIsWaiting } from '../../../../shared/components/dashboard/cards/AiWaiting';
import { onToggleHandle } from '../../../../shared/features/dashboard';
import { get } from '../../../../storage/tableColumn';
var AIsWaitingCardWrapper = function (_a) {
    var selectedFacility = _a.selectedFacility, setActualLayout = _a.setActualLayout, isSelected = _a.isSelected;
    var tableName = 'AiWaitingAmazon';
    var visibleState = get(tableName);
    var tableColumns = [
        {
            name: 'facility_name',
            visible: _has(visibleState, 'facility_name') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.facility_name : true,
            title: 'Facility',
            sortable: false,
        },
    ];
    var _b = useState(false), isLoading = _b[0], setIsLoading = _b[1];
    var _c = useState([]), data = _c[0], setData = _c[1];
    var _d = useState([]), sort = _d[0], setSort = _d[1];
    var _e = useState(true), hideHardwareAI = _e[0], setHideHardwareAI = _e[1];
    useEffect(function () {
        setIsLoading(true);
        Endpoints.amazon
            .getDashboardWaitingActionList({
            query: {
                selected_facility: selectedFacility,
                hide_hardware_ai: +hideHardwareAI,
                onlyActive: 1,
                sort: sort,
            },
        })
            .then(function (response) {
            if (response.data) {
                setData(response.data);
            }
        })
            .finally(function () { return setIsLoading(false); });
    }, [selectedFacility]);
    return (_jsxs(_Fragment, { children: [Boolean(isSelected) && (_jsxs(_Fragment, { children: [_jsx("div", { className: "draggable-handle" }), _jsx("div", { className: "hide-handle", onClick: function () { return onToggleHandle('hide', 'AiWaiting', setActualLayout); } })] })), _jsx(AIsWaiting, { isLoading: isLoading, data: data, tableName: tableName, tableColumns: tableColumns, onChangeHideHardwareAI: setHideHardwareAI, onChangeSort: setSort })] }));
};
export default AIsWaitingCardWrapper;
