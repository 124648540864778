import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { getUoms } from '../../../../../../../helper/chart';
import { copyTextToClipboard } from '../../../../../../../helper/dom';
import { selectMeasure, selectPersonalSettingMeasure, } from '../../../../../../../store/global/useGlobalStore';
import DefaultTooltip from '../../../../../../DefaultTooltip';
export var AvgTwfVelIndicator = function (_a) {
    var chartIdentifier = _a.chartIdentifier, avgTwfVelValue = _a.avgTwfVelValue;
    var measure = selectMeasure();
    var personalSettingMeasure = selectPersonalSettingMeasure();
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "default-chart-label", "data-tooltip-id": "twf-vel-".concat(chartIdentifier), children: _jsxs("span", { onClick: function () { return copyTextToClipboard(String(avgTwfVelValue)); }, className: "me-1 cursor-pointer", children: ["Avg Twf - ", avgTwfVelValue, " ", getUoms(chartIdentifier, measure, personalSettingMeasure)] }) }), _jsx(DefaultTooltip, { place: "left", id: "twf-vel-".concat(chartIdentifier), style: {
                    maxWidth: '300px',
                    textAlign: 'center',
                }, children: avgTwfVelValue })] }));
};
AvgTwfVelIndicator.displayName = 'AvgTwfVelIndicator';
