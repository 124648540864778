var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import { useEffect, useState } from 'react';
import { FaCheckCircle, FaMinusCircle, FaPlusCircle, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import Endpoints from '../../../api/endpoints/endpoints';
import { SPEED_TYPES, SPEED_TYPE_LABELS } from '../../../constants';
import { ChartFeaturesStore } from '../../../shared/store/charts/chartsContent/ChartFeaturesStore';
import { InstallationPointStore } from '../../../shared/store/charts/chartsContent/InstallationPointStore';
var fetchTachometers = function (facility_id, setTachometerNodeList) {
    Endpoints.remastered
        .searchTachometers({ query: { facility_id: facility_id } })
        .then(function (tachometerNodeList) {
        setTachometerNodeList(tachometerNodeList);
    });
};
var EquipmentSpeedModal = function () {
    var setStatesChartFeaturesStore = ChartFeaturesStore(function (state) { return ({
        setStatesChartFeaturesStore: state.setStates,
    }); }, shallow).setStatesChartFeaturesStore;
    var _a = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
        setActiveInstallationPoint: state.setActiveInstallationPoint,
        setStatesInstallationPointStore: state.setStates,
        isRefreshFftReadings: state.isRefreshFftReadings,
    }); }, shallow), installationPoint = _a.installationPoint, setActiveInstallationPoint = _a.setActiveInstallationPoint, setStatesInstallationPointStore = _a.setStatesInstallationPointStore, isRefreshFftReadings = _a.isRefreshFftReadings, facility = _get(installationPoint, 'equipment.locationTable.facility') || {}, equipment = _get(installationPoint, 'equipment') || {};
    var _b = useState({
        currentSpeed: equipment.current_speed || '',
        equipmentSpeedTypeId: _get(equipment, 'equipment_speed_type_id', SPEED_TYPES.FIXED),
        installationPoints: {},
        speedMax: equipment.speed_max,
        speedMin: equipment.speed_min,
        tachometerNodeSerial: _get(equipment, 'tachometer.node.serial', ''),
    }), data = _b[0], setData = _b[1];
    var _c = useState([]), tachometerNodeList = _c[0], setTachometerNodeList = _c[1];
    var _d = useState(!!equipment.tachometer), hasTachometer = _d[0], setHasTachometer = _d[1];
    var _e = useState(false), loader = _e[0], setLoader = _e[1];
    useEffect(function () {
        fetchTachometers(facility.facility_id, setTachometerNodeList);
    }, []);
    var onSave = function () {
        setLoader(true);
        Endpoints.remastered
            .changeSpeedEquipment(equipment.id, data)
            .then(function (resp) {
            var _a;
            setLoader(false);
            if (resp) {
                if (resp.success) {
                    toast.success(resp.message, { icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }) });
                    var speedRatio = _get(data.installationPoints, [installationPoint.id, 'speed_ratio']) ||
                        installationPoint.speed_ratio, speed = (_a = _get(data.installationPoints, [installationPoint.id, 'speed'])) !== null && _a !== void 0 ? _a : installationPoint.speed;
                    setStatesInstallationPointStore({
                        isRefreshFftReadings: !isRefreshFftReadings,
                    });
                    setActiveInstallationPoint(__assign(__assign({}, installationPoint), { speed_ratio: speedRatio, speed: speed, 
                        // @ts-ignore
                        equipment: __assign(__assign({}, installationPoint.equipment), { current_speed: +data.currentSpeed, equipment_speed_type_id: data.equipmentSpeedTypeId, 
                            // @ts-ignore
                            installationPoints: installationPoint.equipment.installationPoints.map(function (installationPoint) {
                                var _a;
                                installationPoint.speed =
                                    (_a = _get(data.installationPoints, [installationPoint.id, 'speed'])) !== null && _a !== void 0 ? _a : installationPoint.speed;
                                return installationPoint;
                            }) }) }));
                    return onCancel();
                }
                return toast.error(resp.message || 'Server error', {
                    icon: _jsx(FaTimes, { style: { fill: '#198754' } }),
                });
            }
        })
            .catch(function () {
            setLoader(false);
        });
    };
    var onCancel = function () {
        setStatesChartFeaturesStore({
            equipmentSpeedVisible: false,
        });
    };
    useEffect(function () {
        setData(__assign(__assign({}, data), { tachometerNodeSerial: hasTachometer ? data.tachometerNodeSerial : '' }));
    }, [hasTachometer]);
    var showRatio = data.equipmentSpeedTypeId == SPEED_TYPES.RATIO ||
        data.equipmentSpeedTypeId == SPEED_TYPES.VARIABLE ||
        data.tachometerNodeSerial;
    var showRpm = data.equipmentSpeedTypeId == SPEED_TYPES.FIXED;
    return (_jsxs(Modal, { className: "modal-response", size: "xl", toggle: onCancel, isOpen: true, children: [_jsx(ModalHeader, { toggle: onCancel, children: "SPEED SETTINGS" }), _jsxs(ModalBody, { children: [_jsxs(Row, { children: [_jsx(Col, { md: 6, children: _jsxs(FormGroup, { children: [_jsx(Label, { children: _jsx("b", { children: "Asset Code:" }) }), _jsx("p", { children: equipment.asset_code })] }) }), _jsx(Col, { md: 6, children: _jsxs(FormGroup, { children: [_jsx(Label, { children: _jsx("b", { children: "Equipment:" }) }), _jsx("p", { children: equipment.name })] }) })] }), _jsx("hr", {}), _jsx(Row, { children: _jsxs(Col, { children: [_jsx("div", { className: "mb-2", children: _jsx("b", { children: "Equipment Speed Settings" }) }), _jsxs(FormGroup, { children: [_jsx(Label, { children: "Equipment Speed Type" }), _jsx("select", { disabled: loader, value: data.equipmentSpeedTypeId, onChange: function (ev) {
                                                return setData(__assign(__assign({}, data), { equipmentSpeedTypeId: +ev.currentTarget.value }));
                                            }, className: "form-control", children: Object.values(SPEED_TYPES).map(function (speedType) { return (_jsx("option", { defaultChecked: speedType === +data.equipmentSpeedTypeId, value: speedType, children: SPEED_TYPE_LABELS[speedType] }, "equipment_speed_type_".concat(speedType))); }) })] }), (+data.equipmentSpeedTypeId === SPEED_TYPES.FIXED ||
                                    +data.equipmentSpeedTypeId === SPEED_TYPES.RATIO) && (_jsxs(FormGroup, { children: [_jsx(Label, { children: data.equipmentSpeedTypeId == SPEED_TYPES.FIXED
                                                ? 'Default Running Speed'
                                                : 'Base Running Speed' }), _jsx(Input, { disabled: loader, name: "equipment_speed", id: "equipment_speed", type: "number", onChange: function (ev) {
                                                return setData(__assign(__assign({}, data), { currentSpeed: ev.currentTarget.value }));
                                            }, value: data.currentSpeed })] })), +data.equipmentSpeedTypeId === SPEED_TYPES.VARIABLE && (_jsx(FormGroup, { children: _jsxs(Row, { children: [_jsxs(Col, { children: [_jsx(Label, { children: "Minimum RPM:" }), _jsx(Input, { disabled: loader, name: "equipment_speed_min", id: "equipment_speed_min", type: "number", onChange: function (ev) {
                                                            return setData(__assign(__assign({}, data), { speedMin: ev.currentTarget.value }));
                                                        }, value: data.speedMin })] }), _jsxs(Col, { children: [_jsx(Label, { children: "Maximum RPM:" }), _jsx(Input, { disabled: loader, name: "equipment_speed_max", id: "equipment_speed_max", type: "number", onChange: function (ev) {
                                                            return setData(__assign(__assign({}, data), { speedMax: ev.currentTarget.value }));
                                                        }, value: data.speedMax })] })] }) })), !hasTachometer && tachometerNodeList.length > 0 && (_jsx(FormGroup, { children: _jsxs("span", { className: "cursor-pointer text-primary", onClick: function () { return setHasTachometer(true); }, children: [_jsx(FaPlusCircle, {}), " ", _jsx("span", { children: "Add new tachometer" })] }) })), tachometerNodeList.length > 0 && hasTachometer && (_jsxs(FormGroup, { children: [_jsx(Label, { children: "Tachometer" }), _jsxs("select", { disabled: loader, value: data.tachometerNodeSerial, onChange: function (ev) {
                                                return setData(__assign(__assign({}, data), { tachometerNodeSerial: +ev.currentTarget.value }));
                                            }, className: "form-control", children: [_jsx("option", { value: "", children: "None" }), tachometerNodeList.map(function (tachometer) { return (_jsx("option", { value: tachometer.serial, children: tachometer.location_info || tachometer.serial }, tachometer.serial)); })] }), _jsxs("span", { className: "cursor-pointer text-primary", onClick: function () { return setHasTachometer(false); }, children: [_jsx(FaMinusCircle, {}), " ", _jsx("span", { children: "Remove tachometer" })] })] }))] }) }), _jsx(Row, { children: _jsxs(Col, { children: [_jsx("div", { className: "mt-2 mb-2", children: _jsx("b", { children: "Installation Point Speed Settings" }) }), equipment.installationPoints.length > 0 ? (_jsx(_Fragment, { children: equipment.installationPoints.map(function (installationPoint) { return (_jsxs(Row, { children: [_jsx(Col, { md: 2, children: _jsx("span", { className: "mt-4 d-inline-block", children: installationPoint.name }) }), showRpm && (_jsx(Col, { md: showRatio ? 5 : 10, children: _jsxs(FormGroup, { children: [_jsx(Label, { children: "CPM Value:" }), _jsx(Input, { disabled: loader, name: "installation_point_speed_cpm", id: "installation_point_speed_cpm", type: "number", onChange: function (ev) {
                                                                var _a;
                                                                return setData(__assign(__assign({}, data), { installationPoints: __assign(__assign({}, data.installationPoints), (_a = {}, _a[installationPoint.id] = __assign(__assign({}, data.installationPoints[installationPoint.id]), { speed: ev.currentTarget.value }), _a)) }));
                                                            }, value: _get(data.installationPoints, [installationPoint.id, 'speed'], installationPoint.speed) || '' })] }) })), !!showRatio && (_jsx(Col, { md: showRpm ? 5 : 10, children: _jsxs(FormGroup, { children: [_jsx(Label, { children: "Ratio Value:" }), _jsx(Input, { disabled: loader, name: "installation_point_speed_ratio", id: "installation_point_speed_ratio", type: "number", onChange: function (ev) {
                                                                var _a;
                                                                return setData(__assign(__assign({}, data), { installationPoints: __assign(__assign({}, data.installationPoints), (_a = {}, _a[installationPoint.id] = __assign(__assign({}, data.installationPoints[installationPoint.id]), { speed_ratio: ev.currentTarget.value }), _a)) }));
                                                            }, value: _get(data.installationPoints, [installationPoint.id, 'speed_ratio'], installationPoint.speed_ratio) || '' })] }) }))] }, "equipment_speed_".concat(installationPoint.id))); }) })) : (_jsx("div", { children: "Installation points not found" }))] }) })] }), _jsx(ModalFooter, { children: _jsxs(ButtonGroup, { className: "response-ignore", children: [_jsx(Button, { size: "sm", color: "secondary", outline: true, className: "me-2", onClick: onCancel, children: "Cancel" }), _jsx(Button, { size: "sm", color: "primary", disabled: loader, onClick: onSave, children: "Save" })] }) })] }));
};
export default EquipmentSpeedModal;
