var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { useEffect, useMemo, useReducer, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { FaEdit } from 'react-icons/fa';
import { Card, CardBody, CardHeader, Input, Label } from 'reactstrap';
import { get } from '../../../../storage/tableColumn';
import { config as baseConfig } from '../../../config/dashboard/customerCreatedAi/dataTableConfig';
import { reducer } from '../../../helper/reducer';
import DefaultTooltip from '../../DefaultTooltip';
import LinkWithPrefix from '../../LinkWithPrefix';
import Loader from '../../Loader';
import DataTable from '../../shared/Table/Table';
var getInitialState = function (tableName, tableColumns, selectedSystemType) {
    var visibleState = get(tableName);
    var config = baseConfig(visibleState, selectedSystemType);
    return {
        config: __assign(__assign({}, config), { columns: __spreadArray(__spreadArray([
                {
                    name: '',
                    component: function (_a) {
                        var id = _a.id;
                        return (_jsxs(_Fragment, { children: [_jsx("div", { className: "flag-customer-created", "data-tooltip-id": "customerCreated".concat(id) }), _jsx(DefaultTooltip, { place: "right", openEvents: { mouseenter: true }, id: "customerCreated".concat(id), children: "Customer's Created" })] }));
                    },
                },
                {
                    name: 'table_menu',
                    visible: true,
                    component: function () {
                        return _jsx(FaEdit, {});
                    },
                }
            ], tableColumns, true), config.columns, true) }),
    };
};
export var CustomerCreatedAi = function (_a) {
    var tableName = _a.tableName, tableColumns = _a.tableColumns, title = _a.title, isLoading = _a.isLoading, isFetching = _a.isFetching, data = _a.data, systemType = _a.systemType, filters = _a.filters, onChangeHideHardwareAI = _a.onChangeHideHardwareAI, onChangeSort = _a.onChangeSort, onScrollEnd = _a.onScrollEnd;
    var _b = useReducer(reducer, getInitialState(tableName, tableColumns, systemType)), state = _b[0], dispatch = _b[1];
    var config = state.config;
    var sort = config.sort;
    var _c = useState(true), hideHardwareAI = _c[0], setHideHardwareAI = _c[1];
    var targetConfig = useMemo(function () {
        return __assign(__assign({}, config), { loader: isLoading, data: data });
    }, [config, isLoading, data]);
    useEffect(function () {
        onChangeHideHardwareAI === null || onChangeHideHardwareAI === void 0 ? void 0 : onChangeHideHardwareAI(hideHardwareAI);
    }, [hideHardwareAI]);
    useEffect(function () {
        onChangeSort === null || onChangeSort === void 0 ? void 0 : onChangeSort(sort || []);
    }, [sort]);
    var handleScrollFrame = function (positionsValues) {
        var clientHeight = positionsValues.clientHeight, scrollHeight = positionsValues.scrollHeight, scrollTop = positionsValues.scrollTop;
        if (scrollTop + clientHeight >= scrollHeight - 1) {
            onScrollEnd === null || onScrollEnd === void 0 ? void 0 : onScrollEnd();
        }
    };
    return (_jsxs(Card, { className: "dashboard-widget", children: [_jsxs(CardHeader, { className: "d-flex justify-content-between", style: { padding: 10 }, children: [_jsx(LinkWithPrefix, { to: "/action-list?state=customer_created", children: _jsx("div", { className: "float-start", children: title }) }), _jsxs("div", { className: "d-flex float-end", children: [_jsxs(Label, { check: true, className: "ms-4", children: [_jsx(Input, { type: "checkbox", defaultChecked: true, onChange: function () { return setHideHardwareAI(function (prev) { return !prev; }); } }), _jsx("span", { children: "Hide network Ais" })] }), filters] })] }), _jsxs(CardBody, { className: clsx('p-0', { 'card-body--is-fetching': !isLoading && isFetching }), children: [!isLoading && isFetching && _jsx(Loader, { variant: "loader-sm" }), _jsx(Scrollbars, { style: { height: '100%' }, onScrollFrame: handleScrollFrame, children: _jsx(DataTable, { tableName: tableName, dispatchTable: dispatch, config: targetConfig }) })] })] }));
};
