import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { shallow } from 'zustand/shallow';
import { isTemperatureChart } from '../../../../../../helper/chart';
import { ChartsStore } from '../../../../../../store/charts/chartsContent/ChartsStore';
import { InstallationPointStore } from '../../../../../../store/charts/chartsContent/InstallationPointStore';
import '../../../../../../styles/charts/chartsWrapper/LabelsChartActions.scss';
import { OverloadedValueIndicator } from '../../shared/labelsChartActionsIndicators/OverloadedValueIndicator';
import WakeOnShakeIndicator from '../../shared/labelsChartActionsIndicators/WakeOnShakeIndicator';
import HidePeaksIndicator from './components/HidePeaksIndicator';
import ZoomActionActivity from './components/ZoomActionActivity';
var LabelsChartActions = function (_a) {
    var chartIdentifier = _a.chartIdentifier, hidePeaksOptions = _a.hidePeaksOptions, isOverloaded = _a.isOverloaded;
    var _b = ChartsStore(function (state) { return ({
        isSelectChartVisible: state.isSelectChartVisible,
        hidePeaks: state.hidePeaks,
        chartAlertLevelsVisibleToggle: state.chartAlertLevelsVisibleToggle,
    }); }, shallow), isSelectChartVisible = _b.isSelectChartVisible, hidePeaks = _b.hidePeaks, chartAlertLevelsVisibleToggle = _b.chartAlertLevelsVisibleToggle;
    var wosTrigger = InstallationPointStore(function (state) { return state.wosTrigger; });
    if (isSelectChartVisible) {
        return null;
    }
    return (_jsxs("div", { className: "labels-chart-actions", children: [isOverloaded && !chartAlertLevelsVisibleToggle && (_jsx(OverloadedValueIndicator, { chartIdentifier: chartIdentifier })), !!hidePeaks && (_jsx(HidePeaksIndicator, { hidePeaksOptions: hidePeaksOptions, chartIdentifier: chartIdentifier })), !!wosTrigger && !isTemperatureChart(chartIdentifier) && (_jsx(WakeOnShakeIndicator, { chartIdentifier: chartIdentifier })), _jsx(ZoomActionActivity, { chartIdentifier: chartIdentifier })] }));
};
export default memo(LabelsChartActions);
