import { jsx as _jsx } from "react/jsx-runtime";
import { FaCheckCircle, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
export var onConfirm = function (setConfirmData, endpoint, callback, installationPointId, readingTypeId) {
    setConfirmData({
        isVisible: true,
        textConfirmationModal: 'Are you sure you want to confirm this alert level?',
        onConfirm: function () {
            setConfirmData({
                isVisible: false,
            });
            endpoint({ entity_id: installationPointId, reading_type_id: readingTypeId }).then(function (resp) {
                if (resp.success) {
                    callback();
                    return toast.success(resp.message || 'Successfully confirmed', {
                        icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                    });
                }
                return toast.error(resp.message || 'Server Error', { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
            });
        },
    });
};
