var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import cx from 'clsx';
import { head as _head } from 'lodash';
import { memo, useEffect } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { FaCheckCircle, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Loader from '../../../../../../../../shared/components/Loader';
import { ASSET_EQUIPMENT_TYPES, ASSET_EQUIPMENT_TYPES_SAVE_IDS, COMPACT_MACHINE_TYPE, DIESEL_ENGINE, DRIVER_A_MOTOR, DRIVER_A_TURBINE, DRIVER_NOT_MONITORED, LAST_STEP_BY_EQUIPMENT_TYPE, NUMBER_OF_STAGES_TYPE_BLADES, NUMBER_OF_STAGES_TYPE_VANES, } from '../../../../constants';
import { getComponentTypeByEquipmentType, getIsViewMode, getStepName } from '../../../../features';
import { firstAssetBuilderSchema } from '../../../../schemas/firstAssetBuilderSchema';
import { useAssetTreeQuery } from '../../../../services/useAssetTreeQuery';
import { useAssetCreateMutation } from '../../../../services/useCreateAssetMutation';
import { selectComponentId } from '../../../../stores/useAssetBuilderStore';
import assetBuilderStyles from '../../styles.module.scss';
import { BackToStep } from '../BackToStep';
import { Title } from '../Title';
import { CompactMachine } from './components/CompactMachine';
import { DieselEngine } from './components/DieselEngine';
import { DriverAMotor } from './components/DriverAMotor';
import { DriverATurbine } from './components/DriverATurbine';
import { FIRST_STEP_TO_DEFAULT_VALUES } from './constants';
var Component = function (equipmentType, control, equipmentId) {
    switch (equipmentType) {
        case DRIVER_A_MOTOR:
            return _jsx(DriverAMotor, { control: control, equipmentId: equipmentId });
        case DRIVER_A_TURBINE:
            return _jsx(DriverATurbine, { control: control, equipmentId: equipmentId });
        case DIESEL_ENGINE:
            return _jsx(DieselEngine, { control: control, equipmentId: equipmentId });
        case COMPACT_MACHINE_TYPE:
            return _jsx(CompactMachine, { control: control, equipmentId: equipmentId });
    }
};
export var FirstAssetContent = memo(function (_a) {
    var _b;
    var _c, _d, _e;
    var equipmentId = _a.equipmentId;
    var _f = useAssetCreateMutation({ equipmentId: equipmentId }), firstAssetCreateMutate = _f.mutate, isPending = _f.isPending;
    var assetTreeData = useAssetTreeQuery({ equipmentId: equipmentId }).data;
    var componentId = selectComponentId();
    var isViewMode = getIsViewMode(assetTreeData === null || assetTreeData === void 0 ? void 0 : assetTreeData.data);
    var componentData = isViewMode
        ? (_c = _head(assetTreeData === null || assetTreeData === void 0 ? void 0 : assetTreeData.data)) === null || _c === void 0 ? void 0 : _c.assetConfigs.find(function (_a) {
            var id = _a.id;
            return id === componentId;
        })
        : null;
    var formData = useForm({
        defaultValues: {
            data: __assign({ step: 1 }, FIRST_STEP_TO_DEFAULT_VALUES),
            equipmentType: '',
        },
        resolver: firstAssetBuilderSchema,
    });
    var setValue = formData.setValue, trigger = formData.trigger, control = formData.control, clearErrors = formData.clearErrors, reset = formData.reset, errors = formData.formState.errors;
    var _g = useWatch({
        control: control,
        name: ['equipmentType', 'data'],
    }), equipmentType = _g[0], data = _g[1];
    var step = data.step, motorBars = data.motorBars, coolingFanBlades = data.coolingFanBlades;
    useEffect(function () {
        if (!isViewMode || !componentData) {
            return;
        }
        reset();
        setTimeout(function () {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4;
            setValue('equipmentType', (_a = componentData === null || componentData === void 0 ? void 0 : componentData.options) === null || _a === void 0 ? void 0 : _a.equipmentType);
            setValue('data', {
                assetModuleId: componentData.assetModuleId,
                componentType: componentData.componentType,
                subtype: componentData.assetComponentTypeId,
                componentOrientation: (_b = componentData === null || componentData === void 0 ? void 0 : componentData.options) === null || _b === void 0 ? void 0 : _b.componentOrientation,
                bearingType: (_c = componentData === null || componentData === void 0 ? void 0 : componentData.options) === null || _c === void 0 ? void 0 : _c.bearingType,
                bearingMonitoredPositionsVariant: 1,
                bearingMonitoredPositions: (_d = componentData === null || componentData === void 0 ? void 0 : componentData.installationPoints) === null || _d === void 0 ? void 0 : _d.map(function (_a) {
                    var assetPointSetting = _a.assetPointSetting, installationPointBearings = _a.installationPointBearings;
                    return ({
                        id: assetPointSetting.bearingPosition,
                        installationPointId: assetPointSetting.installationPointId,
                        installationPointBearings: installationPointBearings,
                    });
                }),
                baseRunningSpeed: (_e = componentData === null || componentData === void 0 ? void 0 : componentData.options) === null || _e === void 0 ? void 0 : _e.baseRunningSpeed,
                speedVariation: (_f = componentData === null || componentData === void 0 ? void 0 : componentData.options) === null || _f === void 0 ? void 0 : _f.speedVariation,
                speed: (_g = componentData === null || componentData === void 0 ? void 0 : componentData.options) === null || _g === void 0 ? void 0 : _g.speed,
                pistonRate: (_h = componentData === null || componentData === void 0 ? void 0 : componentData.options) === null || _h === void 0 ? void 0 : _h.pistonRate,
                motorBars: (_j = componentData === null || componentData === void 0 ? void 0 : componentData.options) === null || _j === void 0 ? void 0 : _j.motorBars,
                coolingFanBlades: (_k = componentData === null || componentData === void 0 ? void 0 : componentData.options) === null || _k === void 0 ? void 0 : _k.coolingFanBlades,
                manufacturer: (_l = componentData === null || componentData === void 0 ? void 0 : componentData.options) === null || _l === void 0 ? void 0 : _l.manufacturer,
                model: (_m = componentData === null || componentData === void 0 ? void 0 : componentData.options) === null || _m === void 0 ? void 0 : _m.model,
                horsePower: (_o = componentData === null || componentData === void 0 ? void 0 : componentData.options) === null || _o === void 0 ? void 0 : _o.horsePower,
                kw: (_p = componentData === null || componentData === void 0 ? void 0 : componentData.options) === null || _p === void 0 ? void 0 : _p.kw,
                frame: (_q = componentData === null || componentData === void 0 ? void 0 : componentData.options) === null || _q === void 0 ? void 0 : _q.frame,
                motorPoles: (_r = componentData === null || componentData === void 0 ? void 0 : componentData.options) === null || _r === void 0 ? void 0 : _r.motorPoles,
                nameplateName: (_s = componentData === null || componentData === void 0 ? void 0 : componentData.options) === null || _s === void 0 ? void 0 : _s.nameplateName,
                nameplateHp: (_t = componentData === null || componentData === void 0 ? void 0 : componentData.options) === null || _t === void 0 ? void 0 : _t.nameplateHp,
                nameplateHz: (_u = componentData === null || componentData === void 0 ? void 0 : componentData.options) === null || _u === void 0 ? void 0 : _u.nameplateHz,
                nameplateFrame: (_v = componentData === null || componentData === void 0 ? void 0 : componentData.options) === null || _v === void 0 ? void 0 : _v.nameplateFrame,
                nameplateShaftEndBearingDE: (_w = componentData === null || componentData === void 0 ? void 0 : componentData.options) === null || _w === void 0 ? void 0 : _w.nameplateShaftEndBearingDE,
                nameplateOppositeEndBearingNDE: (_x = componentData === null || componentData === void 0 ? void 0 : componentData.options) === null || _x === void 0 ? void 0 : _x.nameplateOppositeEndBearingNDE,
                nameplateRPM: (_y = componentData === null || componentData === void 0 ? void 0 : componentData.options) === null || _y === void 0 ? void 0 : _y.nameplateRPM,
                photo: (_z = componentData === null || componentData === void 0 ? void 0 : componentData.options) === null || _z === void 0 ? void 0 : _z.photo,
                scrInfo: (_0 = componentData === null || componentData === void 0 ? void 0 : componentData.options) === null || _0 === void 0 ? void 0 : _0.scrInfo,
                numberOfBlades: (_1 = componentData === null || componentData === void 0 ? void 0 : componentData.options) === null || _1 === void 0 ? void 0 : _1.numberOfBlades,
                overhungRotor: (_2 = componentData === null || componentData === void 0 ? void 0 : componentData.options) === null || _2 === void 0 ? void 0 : _2.overhungRotor,
                numberOfStagesType: (_3 = componentData === null || componentData === void 0 ? void 0 : componentData.options) === null || _3 === void 0 ? void 0 : _3.numberOfStagesType,
                numberOfStages: (_4 = componentData === null || componentData === void 0 ? void 0 : componentData.options) === null || _4 === void 0 ? void 0 : _4.numberOfStages,
                step: 6,
            });
        });
    }, [isViewMode, componentData]);
    var onSave = function () { return __awaiter(void 0, void 0, void 0, function () {
        var isValid, speed, baseRunningSpeed, speedVariation, componentType, assetModuleId, subtype, bearingMonitoredPositions, pistonRate, componentOrientation, bearingType, manufacturer, model, horsePower, kw, frame, motorPoles, nameplateName, nameplateHp, nameplateHz, nameplateFrame, nameplateShaftEndBearingDE, nameplateOppositeEndBearingNDE, nameplateRPM, photo, scrInfo, numberOfBlades, overhungRotor, numberOfStagesType, numberOfStages;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (DRIVER_NOT_MONITORED === equipmentType) {
                        return [2 /*return*/, toast.error(DRIVER_NOT_MONITORED + ' is under development', {
                                icon: _jsx(FaTimes, { style: { fill: '#d63031' } }),
                            })];
                    }
                    return [4 /*yield*/, trigger()];
                case 1:
                    isValid = _a.sent();
                    if (!isValid) {
                        return [2 /*return*/];
                    }
                    if (step === LAST_STEP_BY_EQUIPMENT_TYPE[equipmentType]) {
                        speed = data.speed, baseRunningSpeed = data.baseRunningSpeed, speedVariation = data.speedVariation, componentType = data.componentType, assetModuleId = data.assetModuleId, subtype = data.subtype, bearingMonitoredPositions = data.bearingMonitoredPositions, pistonRate = data.pistonRate, componentOrientation = data.componentOrientation, bearingType = data.bearingType, manufacturer = data.manufacturer, model = data.model, horsePower = data.horsePower, kw = data.kw, frame = data.frame, motorPoles = data.motorPoles, nameplateName = data.nameplateName, nameplateHp = data.nameplateHp, nameplateHz = data.nameplateHz, nameplateFrame = data.nameplateFrame, nameplateShaftEndBearingDE = data.nameplateShaftEndBearingDE, nameplateOppositeEndBearingNDE = data.nameplateOppositeEndBearingNDE, nameplateRPM = data.nameplateRPM, photo = data.photo, scrInfo = data.scrInfo, numberOfBlades = data.numberOfBlades, overhungRotor = data.overhungRotor, numberOfStagesType = data.numberOfStagesType, numberOfStages = data.numberOfStages;
                        firstAssetCreateMutate({
                            asset: {
                                type: ASSET_EQUIPMENT_TYPES_SAVE_IDS[equipmentType],
                                speedSetting: {
                                    baseRunningSpeed: baseRunningSpeed,
                                    speedVariation: speedVariation,
                                    isVariable: Number(speed.variantSpeedType === 'vfd'),
                                    minRpm: speed.speedMin,
                                    maxRpm: speed.speedMax,
                                    isTachometer: Number(speed.variantSpeedType === 'tach'),
                                    tachometerMultiplier: speed.tachMultiplier,
                                    tachometerNodeSerial: speed.tach,
                                },
                            },
                            components: [
                                {
                                    assetModuleId: assetModuleId,
                                    assetComponentId: equipmentType === COMPACT_MACHINE_TYPE ? subtype : componentType,
                                    assetComponentTypeId: equipmentType === COMPACT_MACHINE_TYPE ? undefined : subtype || undefined,
                                    options: {
                                        componentOrientation: componentOrientation,
                                        bearingType: bearingType,
                                        baseRunningSpeed: baseRunningSpeed,
                                        speedVariation: speedVariation,
                                        speed: speed,
                                        pistonRate: pistonRate || undefined,
                                        motorBars: motorBars || undefined,
                                        coolingFanBlades: coolingFanBlades || undefined,
                                        manufacturer: manufacturer,
                                        model: model,
                                        horsePower: horsePower,
                                        kw: kw,
                                        frame: frame,
                                        motorPoles: motorPoles,
                                        nameplateName: nameplateName,
                                        nameplateHp: nameplateHp,
                                        nameplateHz: nameplateHz,
                                        nameplateFrame: nameplateFrame,
                                        nameplateShaftEndBearingDE: nameplateShaftEndBearingDE,
                                        nameplateOppositeEndBearingNDE: nameplateOppositeEndBearingNDE,
                                        nameplateRPM: nameplateRPM,
                                        photo: photo,
                                        equipmentType: equipmentType,
                                        scrInfo: scrInfo,
                                        overhungRotor: overhungRotor,
                                        numberOfStagesType: numberOfStagesType,
                                        numberOfStages: numberOfStages,
                                        numberOfVanes: numberOfStagesType === NUMBER_OF_STAGES_TYPE_VANES ? numberOfStages.stages : undefined,
                                        numberOfBlades: numberOfStagesType === NUMBER_OF_STAGES_TYPE_BLADES
                                            ? numberOfStages.stages
                                            : numberOfBlades,
                                        stage1: numberOfStages.stage1,
                                        stage2: numberOfStages.stage2,
                                    },
                                    installationPoints: bearingMonitoredPositions.map(function (installationPoint) {
                                        return {
                                            installationPointId: installationPoint.installationPointId,
                                            bearingPosition: installationPoint.id,
                                            installationPointBearings: installationPoint.bearings.map(function (bearing) {
                                                var _a;
                                                return _a = {
                                                        bearingId: bearing.bearingId
                                                    },
                                                    _a[bearing.type === 'onShaft' ? 'onShaft' : 'ratio'] = bearing.value,
                                                    _a.plotLinesCount = bearing.plotlines,
                                                    _a;
                                            }),
                                        };
                                    }),
                                },
                            ],
                        }, {
                            onSuccess: function () {
                                toast.success('Successfully created new component', {
                                    icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                                });
                            },
                        });
                        return [2 /*return*/];
                    }
                    setValue('data', __assign(__assign({}, data), { step: step + 1 }));
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(FormProvider, __assign({}, formData, { children: [_jsx(Title, { isViewMode: isViewMode, step: step, stepName: getStepName(step, getComponentTypeByEquipmentType(equipmentType)) }), _jsxs("div", { className: assetBuilderStyles.content, children: [_jsxs("div", { className: assetBuilderStyles.groupBlock, children: [_jsx("div", { className: assetBuilderStyles.sectionsBlock, children: _jsxs("div", { className: assetBuilderStyles.section, children: [_jsx("h3", { className: assetBuilderStyles.sectionTitle, children: _jsx("span", { className: assetBuilderStyles.required, children: "Equipment type" }) }), _jsx("div", { className: cx(assetBuilderStyles.sectionButtons, (_b = {},
                                                _b[assetBuilderStyles.errorBorder] = !!((_d = errors === null || errors === void 0 ? void 0 : errors.equipmentType) === null || _d === void 0 ? void 0 : _d.message),
                                                _b)), children: ASSET_EQUIPMENT_TYPES.map(function (type) {
                                                var _a;
                                                return (_jsx("button", { disabled: step !== 1, onClick: function () {
                                                        clearErrors('equipmentType');
                                                        setValue('equipmentType', type);
                                                    }, className: cx(assetBuilderStyles.button, (_a = {},
                                                        _a[assetBuilderStyles.active] = type === equipmentType,
                                                        _a)), children: type }, type));
                                            }) }), _jsx("div", { className: assetBuilderStyles.sectionErrors, children: (_e = errors === null || errors === void 0 ? void 0 : errors.equipmentType) === null || _e === void 0 ? void 0 : _e.message })] }) }), _jsx(BackToStep, { isViewMode: isViewMode, callback: function () { return setValue('data', __assign({ step: 1 }, FIRST_STEP_TO_DEFAULT_VALUES)); }, isShow: step !== 1 })] }), Component(equipmentType, control, equipmentId), !isViewMode && (_jsx("div", { className: assetBuilderStyles.groupBlock, children: _jsx("div", { className: cx(assetBuilderStyles.sectionsBlock, assetBuilderStyles.full), children: _jsx("div", { className: assetBuilderStyles.section, children: _jsx("button", { className: assetBuilderStyles.next, onClick: onSave, children: isPending ? (_jsx(Loader, { variant: 'loader-exs' })) : (_jsx(_Fragment, { children: step ===
                                            LAST_STEP_BY_EQUIPMENT_TYPE[equipmentType]
                                            ? 'Save'
                                            : 'Next' })) }) }) }) }))] })] })));
});
FirstAssetContent.displayName = 'FirstAssetContent';
