var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ASSET_BEARING_TYPE, ASSET_ORIENTATION_HORIZONTAL } from '../../../../../constants';
export var FOURTH_STEP_TO_DEFAULT_VALUES = {
    manufacturer: undefined,
    model: undefined,
    horsePower: undefined,
    kw: undefined,
    frame: undefined,
    motorPoles: undefined,
    nameplateName: undefined,
    nameplateHp: undefined,
    nameplateHz: undefined,
    nameplateFrame: undefined,
    nameplateShaftEndBearingDE: undefined,
    nameplateOppositeEndBearingNDE: undefined,
    nameplateRPM: undefined,
    photo: undefined,
};
export var THIRD_STEP_TO_DEFAULT_VALUES = __assign(__assign({}, FOURTH_STEP_TO_DEFAULT_VALUES), { baseRunningSpeed: undefined, speedVariation: 4, speed: {
        variantSpeedType: 'base',
        speedMin: undefined,
        speedMax: undefined,
        tachMultiplier: 1,
        tach: undefined,
    }, pistonRate: undefined, motorBars: undefined, coolingFanBlades: undefined, scrInfo: undefined, numberOfBlades: undefined, overhungRotor: undefined });
export var SECOND_STEP_TO_DEFAULT_VALUES = __assign(__assign({}, THIRD_STEP_TO_DEFAULT_VALUES), { bearingMonitoredPositionsVariant: 1, bearingMonitoredPositions: [] });
export var FIRST_STEP_TO_DEFAULT_VALUES = __assign(__assign({}, SECOND_STEP_TO_DEFAULT_VALUES), { assetModuleId: undefined, componentType: undefined, subtype: undefined, componentOrientation: ASSET_ORIENTATION_HORIZONTAL, bearingType: ASSET_BEARING_TYPE.ROLLING_ELEMENT, numberOfStagesType: undefined, numberOfStages: {
        stages: undefined,
        stage1: undefined,
        stage2: undefined,
        stage3: undefined,
        stage4: undefined,
        stage5: undefined,
        stage6: undefined,
        stage7: undefined,
        stage8: undefined,
    } });
