var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { useContext, useEffect, useReducer } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { FaEdit } from 'react-icons/fa';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { SYSTEM_TYPE_REMASTERED } from '../../../../constants';
import { get } from '../../../../storage/tableColumn';
import { config as baseConfig } from '../../../config/dashboard/troubleshootingWaitingAi/dataTableConfig';
import { SystemTypeContext } from '../../../context/SystemTypeContext';
import { reducer } from '../../../helper/reducer';
import LinkWithPrefix from '../../LinkWithPrefix';
import Loader from '../../Loader';
import DataTable from '../../shared/Table/Table';
var initialState = function (tableName, tableColumns, selectedSystemType) {
    var visibleState = get(tableName);
    var config = baseConfig(visibleState, selectedSystemType);
    return {
        config: __assign(__assign({}, config), { columns: __spreadArray(__spreadArray([
                {
                    name: 'table_menu',
                    visible: true,
                    component: function () {
                        return _jsx(FaEdit, {});
                    },
                }
            ], tableColumns, true), config.columns, true) }),
    };
};
export var TroubleshootingWaitingAi = function (_a) {
    var tableName = _a.tableName, tableColumns = _a.tableColumns, data = _a.data, isLoading = _a.isLoading, isFetching = _a.isFetching, onScrollEnd = _a.onScrollEnd, onChangeSort = _a.onChangeSort;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _b = useReducer(reducer, initialState(tableName, tableColumns, selectedSystemType)), state = _b[0], dispatch = _b[1];
    var config = state.config;
    var sort = config.sort;
    useEffect(function () {
        onChangeSort === null || onChangeSort === void 0 ? void 0 : onChangeSort(sort || []);
    }, [sort]);
    var handleScrollFrame = function (positionsValues) {
        var clientHeight = positionsValues.clientHeight, scrollHeight = positionsValues.scrollHeight, scrollTop = positionsValues.scrollTop;
        if (scrollTop + clientHeight >= scrollHeight - 1) {
            onScrollEnd === null || onScrollEnd === void 0 ? void 0 : onScrollEnd();
        }
    };
    var title = selectedSystemType === SYSTEM_TYPE_REMASTERED ? 'Waiting' : 'Question';
    var urlParamState = selectedSystemType === SYSTEM_TYPE_REMASTERED ? 'troubleshoot_waiting_ai' : 'troubleshoot_question_ai';
    return (_jsxs(Card, { className: "dashboard-widget", children: [_jsx(CardHeader, { style: { padding: 10 }, children: _jsx(LinkWithPrefix, { to: "/action-list?state=".concat(urlParamState), children: _jsxs("div", { className: "float-start", children: ["Action Items - Troubleshooting Hold - ", title] }) }) }), _jsxs(CardBody, { className: clsx('p-0', { 'card-body--is-fetching': !isLoading && isFetching }), children: [!isLoading && isFetching && _jsx(Loader, { variant: "loader-sm" }), _jsx(Scrollbars, { style: { height: '100%' }, onScrollFrame: handleScrollFrame, children: _jsx(DataTable, { config: __assign(__assign({}, config), { loader: isLoading, data: data }), tableName: tableName, dispatchTable: dispatch }) })] })] }));
};
