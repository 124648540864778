import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { EquipmentTree } from './components/EqupmentTree';
import { NotAssignedIP } from './components/NotAssignedIP';
import { SetupContent } from './components/SetupContent';
import styles from './styles.module.scss';
import { get as _get } from 'lodash';
import { Button } from 'reactstrap';
import Api from '../../../../api/api';
import { useAssetTreeQuery } from './services/useAssetTreeQuery';
export var AssetBuilder = function (_a) {
    var equipmentId = _a.equipmentId;
    var data = useAssetTreeQuery({ equipmentId: equipmentId }).data;
    return (_jsxs("div", { className: styles.wrapper, children: [_jsxs("aside", { className: styles.aside, children: [_get(data, 'data.0.id') ? (_jsx(_Fragment, { children: _jsx(Button, { color: "danger", className: "mt-2", onClick: function () {
                                return Api.del("all-analytics/asset/".concat(_get(data, 'data.0.id')), {}).finally(function () {
                                    return window.location.reload();
                                });
                            }, children: "Delete Asset" }) })) : (_jsx(_Fragment, {})), _jsx(EquipmentTree, { equipmentId: equipmentId }), _jsx(NotAssignedIP, { equipmentId: equipmentId })] }), _jsx(SetupContent, { equipmentId: equipmentId })] }));
};
AssetBuilder.displayName = 'AssetBuilder';
