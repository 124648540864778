import { valibotResolver } from '@hookform/resolvers/valibot';
import { literal, object, variant } from 'valibot';
import { DRIVER_NOT_MONITORED } from '../constants';
import { compactMachineSchema } from './compactMachineSchema';
import { dieselEngineSchema } from './dieselEngineSchema';
import { driverAMotorSchema } from './driverAMotorSchema';
import { driverATurbineSchema } from './driverATurbineSchema';
var driverNotMonitoredSchema = object({
    equipmentType: literal(DRIVER_NOT_MONITORED),
});
export var firstAssetBuilderSchema = valibotResolver(variant('equipmentType', [
    driverAMotorSchema,
    compactMachineSchema,
    driverATurbineSchema,
    dieselEngineSchema,
    driverNotMonitoredSchema,
], 'Equipment type is required'));
