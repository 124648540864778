import { useMemo } from 'react';
import { isNumeric } from '../../../../../../shared/helper/commonHelper';
export var useValidationErrors = function (_a) {
    var alertLevels = _a.alertLevels;
    var validationErrors = useMemo(function () {
        var errors = [];
        // Saving 2 or more identical alert types (caution/warning/…) with the same alarm type is prohibited (< / >).
        // The user can create 4 different alarm level values ((above and below for caution and above and below for warning),
        // however, if the user tries to set two identical alert types with the same values, one will not be able to save the levels because it does not make sense.
        var firstPair = alertLevels[0];
        var secondPair = alertLevels[1];
        var firstCaution = firstPair.caution, firstWarning = firstPair.warning;
        var _a = secondPair || {}, secondCaution = _a.caution, secondWarning = _a.warning;
        if ((isNumeric(firstCaution.level) &&
            isNumeric((secondCaution === null || secondCaution === void 0 ? void 0 : secondCaution.level) || '') &&
            firstCaution.condition === (secondCaution === null || secondCaution === void 0 ? void 0 : secondCaution.condition)) ||
            (isNumeric(firstWarning.level) &&
                isNumeric((secondWarning === null || secondWarning === void 0 ? void 0 : secondWarning.level) || '') &&
                firstWarning.condition === (secondWarning === null || secondWarning === void 0 ? void 0 : secondWarning.condition))) {
            errors.push('Identical alert types (caution/warning) with the same alarm type is prohibited');
        }
        {
            var message = 'Commas are not allowed in numbers';
            var rule = function (firstPair, secondPair) {
                return [
                    firstPair.caution.level,
                    firstPair.warning.level,
                    secondPair === null || secondPair === void 0 ? void 0 : secondPair.caution.level,
                    secondPair === null || secondPair === void 0 ? void 0 : secondPair.warning.level,
                ].some(function (level) { return level === null || level === void 0 ? void 0 : level.includes(','); });
            };
            if (rule(firstPair, secondPair)) {
                errors.push(message);
            }
        }
        var _b = alertLevels.reduce(function (acc, _a) {
            var caution = _a.caution, warning = _a.warning;
            if (isNumeric(caution.level)) {
                if (caution.condition === '>=') {
                    acc.cautionAbove = +caution.level;
                }
                if (caution.condition === '<=') {
                    acc.cautionBelow = +caution.level;
                }
            }
            if (isNumeric(warning.level)) {
                if (warning.condition === '>=') {
                    acc.warningAbove = +warning.level;
                }
                if (warning.condition === '<=') {
                    acc.warningBelow = +warning.level;
                }
            }
            return acc;
        }, {}), cautionAbove = _b.cautionAbove, cautionBelow = _b.cautionBelow, warningAbove = _b.warningAbove, warningBelow = _b.warningBelow;
        {
            var message = 'The value of "Warning >" must be greater than "Caution >"';
            if (isNumeric(warningAbove) && isNumeric(cautionAbove) && Number(warningAbove) < Number(cautionAbove)) {
                errors.push(message);
            }
        }
        {
            var message = 'The value of "Caution <" must be greater than "Warning <"';
            if (isNumeric(cautionBelow) && isNumeric(warningBelow) && Number(cautionBelow) < Number(warningBelow)) {
                errors.push(message);
            }
        }
        {
            var message = 'The value of "Caution >" must be greater than "Caution <"';
            if (isNumeric(cautionAbove) && isNumeric(cautionBelow) && Number(cautionBelow) > Number(cautionAbove)) {
                errors.push(message);
            }
        }
        {
            var message = 'The value of "Warning >" must be greater than "Warning <"';
            if (isNumeric(warningAbove) && isNumeric(warningBelow) && Number(warningAbove) < Number(warningBelow)) {
                errors.push(message);
            }
        }
        {
            var message = 'The value of "Warning >" must be greater than "Caution <"';
            if (isNumeric(warningAbove) && isNumeric(cautionBelow) && Number(warningAbove) < Number(cautionBelow)) {
                errors.push(message);
            }
        }
        {
            var message = 'The value of "Caution >" must be greater than "Warning <"';
            if (isNumeric(cautionAbove) && isNumeric(warningBelow) && Number(warningBelow) > Number(cautionBelow)) {
                errors.push(message);
            }
        }
        {
            var message = 'Please ensure that the values are not equal';
            var rule = function () {
                var values = [warningAbove, cautionAbove, cautionBelow, warningBelow];
                var numericValues = values.filter(isNumeric).map(Number);
                return new Set(numericValues).size !== numericValues.length;
            };
            if (rule()) {
                errors.push(message);
            }
        }
        return errors;
    }, [alertLevels]);
    return {
        validationErrors: validationErrors,
    };
};
