import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useEffect } from 'react';
import { useController, useWatch } from 'react-hook-form';
import Select from 'react-select';
import { ASSET_EQUIPMENT_TYPES_IDS, COMPACT_MACHINE_TYPE, DIESEL_ENGINE, DRIVER_A_MOTOR, DRIVER_A_TURBINE, } from '../../../../constants';
import { useAssetModuleQuery } from '../../../../services/useAssetModuleQuery';
import assetBuilderStyles from '../../styles.module.scss';
export var ComponentType = memo(function (_a) {
    var _b, _c;
    var control = _a.control, isDisabled = _a.isDisabled;
    var _d = useAssetModuleQuery(), data = _d.data, isLoading = _d.isLoading, isFetching = _d.isFetching;
    var equipmentType = useWatch({
        control: control,
        name: 'equipmentType',
    });
    var item = (_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.find(function (_a) {
        var id = _a.id;
        return id === ASSET_EQUIPMENT_TYPES_IDS[equipmentType];
    });
    var options = COMPACT_MACHINE_TYPE === equipmentType
        ? [
            {
                label: item === null || item === void 0 ? void 0 : item.name,
                value: item === null || item === void 0 ? void 0 : item.id,
                assetModuleId: item === null || item === void 0 ? void 0 : item.id,
            },
        ]
        : ((_c = item === null || item === void 0 ? void 0 : item.assetComponents) !== null && _c !== void 0 ? _c : []).map(function (_a) {
            var name = _a.name, id = _a.id, assetModuleId = _a.assetModuleId;
            return {
                label: name,
                value: id,
                assetModuleId: assetModuleId,
            };
        });
    var onChangeSubtype = useController({
        name: 'data.subtype',
        control: control,
    }).field.onChange;
    var _e = useController({
        name: 'data.componentType',
        control: control,
    }).field, componentTypeValue = _e.value, onChangeComponentType = _e.onChange;
    var onChangeAssetModuleId = useController({
        name: 'data.assetModuleId',
        control: control,
    }).field.onChange;
    useEffect(function () {
        onChangeSubtype('');
        if (DRIVER_A_MOTOR === equipmentType) {
            var data_1 = options.find(function (el) { return el.label === 'Motor'; });
            if (!data_1) {
                return;
            }
            onChangeComponentType(data_1.value);
            onChangeAssetModuleId(data_1.assetModuleId);
        }
        if (DRIVER_A_TURBINE === equipmentType) {
            var data_2 = options.find(function (el) { return el.label === 'Turbine'; });
            if (!data_2) {
                return;
            }
            onChangeComponentType(data_2.value);
            onChangeAssetModuleId(data_2.assetModuleId);
        }
        if (DIESEL_ENGINE === equipmentType) {
            var data_3 = options.find(function (el) { return el.label === 'Diesel Engine'; });
            if (!data_3) {
                return;
            }
            onChangeComponentType(data_3.value);
            onChangeAssetModuleId(data_3.assetModuleId);
        }
        if (COMPACT_MACHINE_TYPE === equipmentType) {
            onChangeComponentType(item === null || item === void 0 ? void 0 : item.id);
            onChangeAssetModuleId(item === null || item === void 0 ? void 0 : item.id);
        }
    }, [equipmentType, data]);
    return (_jsxs("div", { className: assetBuilderStyles.section, children: [_jsx("h3", { className: assetBuilderStyles.sectionTitle, children: _jsx("span", { className: assetBuilderStyles.required, children: "Component Type" }) }), _jsx(Select, { placeholder: "Select Component Type", classNamePrefix: "customSelectStyleDefault", isLoading: isLoading || isFetching, isDisabled: isDisabled, value: options.find(function (el) { return el.value === componentTypeValue; }), onChange: function (data) {
                    onChangeSubtype('');
                    onChangeComponentType(data.value);
                    onChangeAssetModuleId(data.assetModuleId);
                }, options: options })] }));
});
ComponentType.displayName = 'ComponentType';
