import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useController, useFormContext, useWatch } from 'react-hook-form';
import Select from 'react-select';
import { ASSET_EQUIPMENT_TYPES_IDS, COMPACT_MACHINE_TYPE } from '../../../../../../constants';
import { useAssetModuleQuery } from '../../../../../../services/useAssetModuleQuery';
import assetBuilderStyles from '../../../../styles.module.scss';
export var Subtype = function (_a) {
    var _b, _c;
    var isDisabled = _a.isDisabled;
    var _d = useAssetModuleQuery(), moduleData = _d.data, isLoading = _d.isLoading, isFetching = _d.isFetching;
    var _e = useFormContext(), clearErrors = _e.clearErrors, control = _e.control;
    var _f = useWatch({
        control: control,
        name: ['equipmentType', 'data'],
    }), equipmentType = _f[0], data = _f[1];
    var componentType = data.componentType, componentOrientation = data.componentOrientation;
    var componentTypeItem = (_b = moduleData === null || moduleData === void 0 ? void 0 : moduleData.data) === null || _b === void 0 ? void 0 : _b.find(function (_a) {
        var id = _a.id;
        return id === ASSET_EQUIPMENT_TYPES_IDS[equipmentType];
    });
    var subtypeItem = componentTypeItem === null || componentTypeItem === void 0 ? void 0 : componentTypeItem.assetComponents.find(function (_a) {
        var id = _a.id;
        return id === componentType;
    });
    var options = ((_c = (COMPACT_MACHINE_TYPE === equipmentType
        ? componentTypeItem === null || componentTypeItem === void 0 ? void 0 : componentTypeItem.assetComponents
        : subtypeItem === null || subtypeItem === void 0 ? void 0 : subtypeItem.assetComponentTypes)) !== null && _c !== void 0 ? _c : []).map(function (_a) {
        var name = _a.name, id = _a.id;
        return {
            label: name,
            value: id,
        };
    });
    var _g = useController({
        name: 'data.subtype',
        control: control,
    }), _h = _g.field, value = _h.value, onChange = _h.onChange, error = _g.fieldState.error;
    var onChangeMotorBars = useController({
        name: 'data.motorBars',
        control: control,
    }).field.onChange;
    var onChangeScrInfo = useController({
        name: 'data.scrInfo',
        control: control,
    }).field.onChange;
    if (!componentOrientation) {
        return null;
    }
    return (_jsxs("div", { className: assetBuilderStyles.section, children: [_jsx("h3", { className: assetBuilderStyles.sectionTitle, children: _jsx("span", { className: assetBuilderStyles.required, children: "Subtype" }) }), _jsx(Select, { placeholder: "Select Subtype", classNamePrefix: "customSelectStyleDefault", isLoading: isLoading || isFetching, isDisabled: isDisabled, value: options.find(function (el) { return el.value === value; }), onChange: function (_a) {
                    var value = _a.value;
                    clearErrors('data.subtype');
                    onChangeScrInfo(undefined);
                    onChangeMotorBars(undefined);
                    onChange(value);
                }, options: options }), _jsx("div", { className: assetBuilderStyles.sectionErrors, children: error === null || error === void 0 ? void 0 : error.message })] }));
};
Subtype.displayName = 'Subtype';
