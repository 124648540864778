import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { has as _has } from 'lodash';
import { useState } from 'react';
import { TroubleshootingWaitingAi } from '../../../../shared/components/dashboard/cards/TroubleshootingWaitingAi';
import { onToggleHandle } from '../../../../shared/features/dashboard';
import { get } from '../../../../storage/tableColumn';
import { useTroubleshootingWaitingAIsInfinityQuery } from '../../../services/actionItems/useTroubleshootingWaitingAIsInfinityQuery';
import { selectSelectedCustomers } from '../../../store/SelectedCustomersStore';
import { selectSelectedLocations } from '../../../store/SelectedLocationsStore';
var TroubleshootingWaitingAiCardWrapper = function (_a) {
    var setActualLayout = _a.setActualLayout, isSelected = _a.isSelected;
    var selectedCustomers = selectSelectedCustomers();
    var selectedLocations = selectSelectedLocations();
    var tableName = 'TroubleshootingWaitingAiRemastered';
    var visibleState = get(tableName);
    var tableColumns = [
        {
            name: 'customer_name',
            visible: _has(visibleState, 'customer_name') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.customer_name : true,
            title: 'Customer',
            sortable: false,
        },
    ];
    var _b = useState([]), sort = _b[0], setSort = _b[1];
    var _c = useTroubleshootingWaitingAIsInfinityQuery({
        selectedCustomers: selectedCustomers,
        selectedLocations: selectedLocations,
        sort: sort,
    }), _d = _c.data, data = _d === void 0 ? [] : _d, isLoading = _c.isLoading, isFetching = _c.isFetching, fetchNextPage = _c.fetchNextPage;
    var handleScrollEnd = function () {
        if (!isFetching) {
            fetchNextPage();
        }
    };
    return (_jsxs(_Fragment, { children: [Boolean(isSelected) && (_jsxs(_Fragment, { children: [_jsx("div", { className: "draggable-handle" }), _jsx("div", { className: "hide-handle", onClick: function () { return onToggleHandle('hide', 'TroubleshootingWaitingAi', setActualLayout); } })] })), _jsx(TroubleshootingWaitingAi, { data: data, tableName: tableName, tableColumns: tableColumns, isLoading: isLoading, isFetching: isFetching, onChangeSort: setSort, onScrollEnd: handleScrollEnd })] }));
};
export default TroubleshootingWaitingAiCardWrapper;
