var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { useContext, useEffect, useReducer, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { FaEdit } from 'react-icons/fa';
import { Card, CardBody, CardHeader, Input, Label } from 'reactstrap';
import { get } from '../../../../storage/tableColumn';
import { config as baseConfig } from '../../../config/dashboard/increasedSeverityAi/dataTableConfig';
import { SystemTypeContext } from '../../../context/SystemTypeContext';
import { reducer } from '../../../helper/reducer';
import LinkWithPrefix from '../../LinkWithPrefix';
import Loader from '../../Loader';
import DataTable from '../../shared/Table/Table';
var initialState = function (tableName, tableColumns, selectedSystemType) {
    var visibleState = get(tableName);
    var config = baseConfig(visibleState, selectedSystemType);
    return {
        config: __assign(__assign({}, config), { columns: __spreadArray(__spreadArray([
                {
                    name: 'table_menu',
                    visible: true,
                    component: function () {
                        return _jsx(FaEdit, {});
                    },
                }
            ], tableColumns, true), config.columns, true) }),
    };
};
export var IncreasedSeverityAi = function (_a) {
    var tableName = _a.tableName, tableColumns = _a.tableColumns, data = _a.data, onRemoveAI = _a.onRemoveAI, onChangeHideHardwareAI = _a.onChangeHideHardwareAI, isLoading = _a.isLoading, isFetching = _a.isFetching, onScrollEnd = _a.onScrollEnd;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _b = useReducer(reducer, initialState(tableName, tableColumns, selectedSystemType)), state = _b[0], dispatch = _b[1];
    var config = state.config;
    var _c = useState(true), hideHardwareAi = _c[0], setHideHardwareAi = _c[1];
    useEffect(function () {
        onChangeHideHardwareAI === null || onChangeHideHardwareAI === void 0 ? void 0 : onChangeHideHardwareAI(hideHardwareAi);
    }, [hideHardwareAi]);
    var handleScrollFrame = function (positionsValues) {
        var clientHeight = positionsValues.clientHeight, scrollHeight = positionsValues.scrollHeight, scrollTop = positionsValues.scrollTop;
        if (scrollTop + clientHeight >= scrollHeight - 1) {
            onScrollEnd === null || onScrollEnd === void 0 ? void 0 : onScrollEnd();
        }
    };
    return (_jsxs(Card, { className: "dashboard-widget", children: [_jsxs(CardHeader, { className: "d-flex justify-content-between", style: { padding: 10 }, children: [_jsx(LinkWithPrefix, { to: "/action-list?state=increaseSeverity", children: _jsx("div", { className: "float-start", children: "Increased Severity AI" }) }), _jsx("div", { children: _jsxs(Label, { check: true, className: "float-end", children: [_jsx(Input, { type: "checkbox", defaultChecked: true, onChange: function () { return setHideHardwareAi(!hideHardwareAi); } }), _jsx("span", { children: "Hide network Ais" })] }) })] }), _jsxs(CardBody, { className: clsx('p-0', { 'card-body--is-fetching': !isLoading && isFetching }), children: [!isLoading && isFetching && _jsx(Loader, { variant: "loader-sm" }), _jsx(Scrollbars, { style: { height: '100%' }, onScrollFrame: handleScrollFrame, children: _jsx(DataTable, { config: __assign(__assign({}, config), { loader: isLoading, data: data }), tableName: tableName, dispatchTable: dispatch, callbacks: {
                                onRemove: onRemoveAI,
                            } }) })] })] }));
};
