import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { has as _has } from 'lodash';
import { memo, useRef, useState } from 'react';
import { ProcessingIncreaseSeverityModal, } from '@sharedModals/AIPreAi/ProcessingIncreaseSeverityModal';
import { IncreasedSeverityAi, } from '../../../../shared/components/dashboard/cards/IncreasedSeverityAi';
import { onToggleHandle } from '../../../../shared/features/dashboard';
import { get } from '../../../../storage/tableColumn';
import { useIncreasedSeverityAIsInfinityQuery } from '../../../services/actionItems/useIncreasedSeverityAIsInfinityQuery';
import { selectSelectedCustomers } from '../../../store/SelectedCustomersStore';
import { selectSelectedLocations } from '../../../store/SelectedLocationsStore';
var IncreasedSeverityAiCardWrapper = function (_a) {
    var setActualLayout = _a.setActualLayout, isSelected = _a.isSelected;
    var selectedCustomers = selectSelectedCustomers();
    var selectedLocations = selectSelectedLocations();
    var tableName = 'IncreasedSeverityAiRemastered';
    var visibleState = get(tableName);
    var tableColumns = [
        {
            name: 'customer_name',
            visible: _has(visibleState, 'customer_name') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.customer_name : true,
            title: 'Customer',
            sortable: false,
        },
    ];
    var _b = useState(true), hideHardwareAI = _b[0], setHideHardwareAI = _b[1];
    var _c = useIncreasedSeverityAIsInfinityQuery({
        selectedCustomers: selectedCustomers,
        selectedLocations: selectedLocations,
        hideHardwareAI: hideHardwareAI,
    }), _d = _c.data, data = _d === void 0 ? [] : _d, isLoading = _c.isLoading, isFetching = _c.isFetching, fetchNextPage = _c.fetchNextPage, refetchAIs = _c.refetch;
    var handleScrollEnd = function () {
        if (!isFetching) {
            fetchNextPage();
        }
    };
    var processingIncreaseSeverityModalRef = useRef({});
    var handleRemoveAI = function (_a) {
        var _b, _c;
        var id = _a.id;
        (_c = (_b = processingIncreaseSeverityModalRef.current).deleteSeverityIncrease) === null || _c === void 0 ? void 0 : _c.call(_b, {
            isPreAI: false,
            itemId: id,
            isVisibleModal: true,
            storage: null, // FIXME: add storage
        }).then(function (ok) {
            if (ok)
                refetchAIs();
        });
    };
    return (_jsxs(_Fragment, { children: [Boolean(isSelected) && (_jsxs(_Fragment, { children: [_jsx("div", { className: "draggable-handle" }), _jsx("div", { className: "hide-handle", onClick: function () { return onToggleHandle('hide', 'IncreasedSeverityAi', setActualLayout); } })] })), _jsx(IncreasedSeverityAi, { data: data, tableName: tableName, tableColumns: tableColumns, isLoading: isLoading, isFetching: isFetching, onChangeHideHardwareAI: setHideHardwareAI, onScrollEnd: handleScrollEnd, onRemoveAI: handleRemoveAI }), _jsx(ProcessingIncreaseSeverityModal, { ref: processingIncreaseSeverityModalRef })] }));
};
export default memo(IncreasedSeverityAiCardWrapper);
