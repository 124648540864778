import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { has as _has } from 'lodash';
import { useState } from 'react';
import { Input, Label } from 'reactstrap';
import { SYSTEM_TYPE_REMASTERED } from '../../../../constants';
import DefaultTooltip from '../../../../shared/components/DefaultTooltip';
import { CustomerCreatedAi } from '../../../../shared/components/dashboard/cards/CustomerCreatedAi';
import { onToggleHandle } from '../../../../shared/features/dashboard';
import { get } from '../../../../storage/tableColumn';
import { useCustomerCreatedInfinityQuery } from '../../../services/actionItems/useCustomerCreatedInfinityQuery';
import { selectSelectedCustomers } from '../../../store/SelectedCustomersStore';
import { selectSelectedLocations } from '../../../store/SelectedLocationsStore';
var CustomerCreatedAiCardWrapper = function (_a) {
    var setActualLayout = _a.setActualLayout, isSelected = _a.isSelected;
    var selectedCustomers = selectSelectedCustomers();
    var selectedLocations = selectSelectedLocations();
    var tableName = 'CustomerCreatedAiRemastered';
    var visibleState = get(tableName);
    var tableColumns = [
        {
            name: 'customer_name',
            visible: _has(visibleState, 'customer_name') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.customer_name : true,
            title: 'Customer',
            sortable: false,
            component: function (_a) {
                var customer_name = _a.customer_name, is_customer_request = _a.is_customer_request, id = _a.id;
                return (_jsxs("span", { className: "customer-name", children: [customer_name, !!is_customer_request && (_jsxs(_Fragment, { children: [_jsx("span", { className: "sub-badge", "data-tooltip-id": "customerRequestTag".concat(id), children: "CR" }), _jsx(DefaultTooltip, { place: "right", openEvents: { mouseenter: true }, id: "customerRequestTag".concat(id), children: "Customer's Request tag" })] }))] }));
            },
        },
    ];
    var _b = useState(true), hideHardwareAI = _b[0], setHideHardwareAI = _b[1];
    var _c = useState([]), sort = _c[0], setSort = _c[1];
    var _d = useState(true), onlyCustomerRequest = _d[0], setOnlyCustomerRequest = _d[1];
    var _e = useCustomerCreatedInfinityQuery({
        hideHardwareAI: hideHardwareAI,
        selectedCustomers: selectedCustomers,
        selectedLocations: selectedLocations,
        sort: sort,
        onlyCustomerRequest: onlyCustomerRequest,
    }), _f = _e.data, data = _f === void 0 ? [] : _f, isLoading = _e.isLoading, isFetching = _e.isFetching, fetchNextPage = _e.fetchNextPage;
    var handleScrollEnd = function () {
        if (!isFetching) {
            fetchNextPage();
        }
    };
    return (_jsxs(_Fragment, { children: [isSelected && (_jsxs(_Fragment, { children: [_jsx("div", { className: "draggable-handle" }), _jsx("div", { className: "hide-handle", onClick: function () { return onToggleHandle('hide', 'CustomerCreatedAi', setActualLayout); } })] })), _jsx(CustomerCreatedAi, { title: "Action Items - Customer Created", tableName: tableName, tableColumns: tableColumns, data: data, isLoading: isLoading, isFetching: isFetching, systemType: SYSTEM_TYPE_REMASTERED, onChangeSort: setSort, onChangeHideHardwareAI: setHideHardwareAI, onScrollEnd: handleScrollEnd, filters: _jsxs(Label, { check: true, className: "ms-4 me-2", children: [_jsx(Input, { type: "checkbox", defaultChecked: true, onChange: function () { return setOnlyCustomerRequest(!onlyCustomerRequest); } }), _jsx("span", { children: "Customer's Request" })] }) })] }));
};
export default CustomerCreatedAiCardWrapper;
