var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { literal, object, variant } from 'valibot';
import { COMPACT_MACHINE_TYPE, NUMBER_OF_STAGES_TYPE_BLADES, NUMBER_OF_STAGES_TYPE_VANES, } from '../constants';
import { assetModuleIdRules } from './shared/assetModuleIdRules';
import { baseRunningSpeedRules } from './shared/baseRunningSpeedRules';
import { bearingMonitoredPositionsRules } from './shared/bearingMonitoredPositionsRules';
import { bearingTypeRules } from './shared/bearingTypeRules';
import { componentOrientationRules } from './shared/componentOrientationRules';
import { componentTypeRules } from './shared/componentTypeRules';
import { manufacturerRules } from './shared/manufacturerRules';
import { modelRules } from './shared/modelRules';
import { numberOfStagesRules } from './shared/numberOfStagesRules';
import { numberOfStagesTypeRules } from './shared/numberOfStagesTypeRules';
import { overhungRotorRules } from './shared/overhungRotorRules';
import { speedRules } from './shared/speedRules';
import { speedVariationRules } from './shared/speedVariationRules';
import { subtypeRules } from './shared/subtypeRules';
var compactMachineSecondStepSchema = {
    assetModuleId: assetModuleIdRules,
    componentType: componentTypeRules,
    subtype: subtypeRules,
    componentOrientation: componentOrientationRules,
    bearingType: bearingTypeRules,
    numberOfStagesType: numberOfStagesTypeRules,
    numberOfStages: numberOfStagesRules([NUMBER_OF_STAGES_TYPE_BLADES, NUMBER_OF_STAGES_TYPE_VANES]),
};
var compactMachineThirdStepSchema = {
    bearingMonitoredPositions: bearingMonitoredPositionsRules([1, 2]),
};
var compactMachineFourthStepSchema = {
    baseRunningSpeed: baseRunningSpeedRules,
    speedVariation: speedVariationRules,
    speed: speedRules,
    overhungRotor: overhungRotorRules,
};
var compactMachineFifthStepSchema = {
    manufacturer: manufacturerRules,
    model: modelRules,
};
export var compactMachineSchema = object({
    equipmentType: literal(COMPACT_MACHINE_TYPE),
    data: variant('step', [
        object({
            step: literal(1),
        }),
        object(__assign({ step: literal(2) }, compactMachineSecondStepSchema)),
        object(__assign(__assign({ step: literal(3) }, compactMachineSecondStepSchema), compactMachineThirdStepSchema)),
        object(__assign(__assign(__assign({ step: literal(4) }, compactMachineSecondStepSchema), compactMachineThirdStepSchema), compactMachineFourthStepSchema)),
        object(__assign(__assign(__assign(__assign({ step: literal(5) }, compactMachineSecondStepSchema), compactMachineThirdStepSchema), compactMachineFourthStepSchema), compactMachineFifthStepSchema)),
    ], 'Step is required'),
});
