var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { literal, object, variant } from 'valibot';
import { DRIVER_A_MOTOR } from '../constants';
import { assetModuleIdRules } from './shared/assetModuleIdRules';
import { baseRunningSpeedRules } from './shared/baseRunningSpeedRules';
import { bearingMonitoredPositionsRules } from './shared/bearingMonitoredPositionsRules';
import { bearingTypeRules } from './shared/bearingTypeRules';
import { componentOrientationRules } from './shared/componentOrientationRules';
import { componentTypeRules } from './shared/componentTypeRules';
import { coolingFanBladesRules } from './shared/coolingFanBladesRules';
import { manufacturerRules } from './shared/manufacturerRules';
import { modelRules } from './shared/modelRules';
import { motorBarsRules } from './shared/motorBarsRules';
import { speedRules } from './shared/speedRules';
import { speedVariationRules } from './shared/speedVariationRules';
import { subtypeRules } from './shared/subtypeRules';
var driverAMotorSecondStepSchema = {
    assetModuleId: assetModuleIdRules,
    componentType: componentTypeRules,
    subtype: subtypeRules,
    componentOrientation: componentOrientationRules,
    bearingType: bearingTypeRules,
};
var driverAMotorThirdStepSchema = {
    bearingMonitoredPositions: bearingMonitoredPositionsRules([1, 2]),
};
var driverAMotorFourthStepSchema = {
    baseRunningSpeed: baseRunningSpeedRules,
    speedVariation: speedVariationRules,
    speed: speedRules,
    motorBars: motorBarsRules,
    coolingFanBlades: coolingFanBladesRules,
};
var driverAMotorFifthStepSchema = {
    manufacturer: manufacturerRules,
    model: modelRules,
    // horsePower: optional(number('Horse Power is number type')),
    // kw: optional(number('KW is number type')),
    // frame: optional(number('Frame is number type')),
    // motorPoles: optional(number('Motor Poles is number type')),
    // nameplateName:
    // nameplateHp:
    // nameplateHz:
    // nameplateFrame:
    // nameplateShaftEndBearingDE:
    // nameplateOppositeEndBearingNDE:
    // nameplateRPM:
    // photo:
};
export var driverAMotorSchema = object({
    equipmentType: literal(DRIVER_A_MOTOR),
    data: variant('step', [
        object({
            step: literal(1),
        }),
        object(__assign({ step: literal(2) }, driverAMotorSecondStepSchema)),
        object(__assign(__assign({ step: literal(3) }, driverAMotorSecondStepSchema), driverAMotorThirdStepSchema)),
        object(__assign(__assign(__assign({ step: literal(4) }, driverAMotorSecondStepSchema), driverAMotorThirdStepSchema), driverAMotorFourthStepSchema)),
        object(__assign(__assign(__assign(__assign({ step: literal(5) }, driverAMotorSecondStepSchema), driverAMotorThirdStepSchema), driverAMotorFourthStepSchema), driverAMotorFifthStepSchema)),
    ], 'Step is required'),
});
