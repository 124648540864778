import { COMPACT_MACHINE_TYPE, DIESEL_ENGINE, DRIVEN_SUBTYPE_SIMPLE_SHAFT, DRIVER_A_MOTOR, DRIVER_A_TURBINE, DRIVER_NOT_MONITORED, } from '../constants';
export var getStepName = function (step, type) {
    if (type === 'driver') {
        var STEP_NAMES = {
            1: 'SET UP A COMPONENT TYPE',
            2: 'SET UP A COMPONENT INFORMATION',
            3: "SET UP A COMPONENT'S INSTALLATION POINTS",
            4: 'SET UP A COMPONENT RPM INFORMATION',
            5: 'SET UP A COMPONENT ADDITIONAL INFORMATION',
        };
        return STEP_NAMES[step] || 'Something went wrong';
    }
    if (type === 'compactMachine') {
        var STEP_NAMES = {
            1: 'SET UP A COMPONENT TYPE',
            2: 'SET UP A COMPONENT INFORMATION',
            3: "SET UP A COMPONENT'S INSTALLATION POINTS",
            4: 'SET UP A COMPONENT RPM INFORMATION',
            5: 'SET UP A COMPONENT ADDITIONAL INFORMATION',
        };
        return STEP_NAMES[step] || 'Something went wrong';
    }
    if (type === 'driverNotMonitored') {
        var STEP_NAMES = {
            1: 'SET UP A COMPONENT TYPE',
            2: 'SET UP A COMPONENT INFORMATION',
            3: "SET UP A COMPONENT'S INSTALLATION POINTS",
            4: 'SET UP A COMPONENT RPM INFORMATION',
            5: 'SET UP A COMPONENT ADDITIONAL INFORMATION',
        };
        return STEP_NAMES[step] || 'Something went wrong';
    }
    if (type === 'gearbox') {
        var STEP_NAMES = {
            1: 'SET UP A GEARBOX COMPONENT TYPE',
            2: 'SET UP A COMPONENT INFORMATION',
            3: "SET UP A COMPONENT'S TRANSMISSION",
            4: 'SET UP A GEARBOX INFORMATION',
            5: 'SET UP A GEARBOX RPM INFORMATION',
            6: 'SET UP A GEARBOX ADDITIONAL INFORMATION',
        };
        return STEP_NAMES[step] || 'Something went wrong';
    }
    if (type === 'driven') {
        var STEP_NAMES = {
            1: 'SET UP AN ANOTHER COMPONENT',
            2: 'SET UP AN ANOTHER COMPONENT',
            3: "SET UP A COMPONENT'S TRANSMISSION",
            4: 'SET UP A COMPONENT INFORMATION',
            5: "SET UP A COMPONENT'S INSTALLATION POINTS",
            6: 'SET UP A COMPONENT ADDITIONAL INFORMATION',
        };
        return STEP_NAMES[step] || 'Something went wrong';
    }
    if (type === 'foundation') {
        var STEP_NAMES = {
            1: 'SET UP A GEARBOX COMPONENT TYPE',
            2: 'SET UP AN ANOTHER COMPONENT',
            3: 'SET UP AN ASSET FOUNDATION',
        };
        return STEP_NAMES[step] || 'Something went wrong';
    }
    return 'Something went wrong';
};
export var getComponentType = function (step, isGearbox, isDriven) {
    if (step === 1) {
        return 'gearbox';
    }
    else {
        if (isGearbox) {
            return 'gearbox';
        }
    }
    return isDriven ? 'driven' : 'foundation';
};
export var getComponentTypeByEquipmentType = function (equipmentType, isGearbox) {
    if ([DRIVER_A_MOTOR, DRIVER_A_TURBINE, DIESEL_ENGINE].includes(equipmentType)) {
        return 'driver';
    }
    if (COMPACT_MACHINE_TYPE === equipmentType) {
        return 'compactMachine';
    }
    if (DRIVER_NOT_MONITORED === equipmentType) {
        return 'driverNotMonitored';
    }
    return 'driver';
};
export var getEntryBoxNumber = function (assetConfigs) {
    return (assetConfigs !== null && assetConfigs !== void 0 ? assetConfigs : []).reduce(function (acc, entry) {
        if (entry.assetModuleName === 'Driver' || entry.assetModuleName === 'Compact Machine') {
            return acc + 2;
        }
        if (entry.assetModuleName === 'Gearbox') {
            return entry.options.bearingMonitoredPositionsVariant === 1 ? acc + 4 : acc + 5;
        }
        if (entry.assetModuleName === 'Driven') {
            return entry.options.bearingMonitoredPositionsVariant === 1 &&
                entry.assetComponentName !== DRIVEN_SUBTYPE_SIMPLE_SHAFT
                ? acc + 2
                : acc + 4;
        }
        return acc;
    }, 0);
};
export var getIsViewMode = function (assetTreeData) {
    return assetTreeData ? assetTreeData[0].status === 1 : false;
};
