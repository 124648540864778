import { useQuery as useUnTypedQuery } from '@tanstack/react-query';
import Api from '../../../api/api';
var useQuery = useUnTypedQuery;
export var increasedSeverityAIsQueryFn = function (_a) {
    var hideHardwareAI = _a.hideHardwareAI, _b = _a.selectedFacility, selectedFacility = _b === void 0 ? '' : _b;
    return Api.get('amazon-analytic/action-list/increased-severity', {
        query: {
            selected_facility: selectedFacility,
            hide_hardware_ai: +hideHardwareAI,
            onlyActive: 1,
        },
    });
};
export var increasedSeverityAIsQueryKey = function (payload) { return [
    'increasedSeverityAIs',
    payload,
]; };
export var useIncreasedSeverityAIsQuery = function (_a) {
    var hideHardwareAI = _a.hideHardwareAI, selectedFacility = _a.selectedFacility;
    return useQuery({
        queryKey: increasedSeverityAIsQueryKey({ hideHardwareAI: hideHardwareAI, selectedFacility: selectedFacility }),
        queryFn: function () {
            return increasedSeverityAIsQueryFn({
                hideHardwareAI: hideHardwareAI,
                selectedFacility: selectedFacility,
            });
        },
        select: function (response) { return response.data; },
    });
};
