import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { has as _has } from 'lodash';
import { useEffect, useState } from 'react';
import { Label } from 'reactstrap';
import { Input } from 'reactstrap';
import Endpoints from '../../../../api/endpoints/endpoints';
import { SYSTEM_TYPE_AMAZON } from '../../../../constants';
import { CustomerCreatedAi } from '../../../../shared/components/dashboard/cards/CustomerCreatedAi';
import { onToggleHandle } from '../../../../shared/features/dashboard';
import { get } from '../../../../storage/tableColumn';
var CustomerCreatedAiCardWrapper = function (_a) {
    var refreshFlag = _a.refreshFlag, selectedFacility = _a.selectedFacility, setActualLayout = _a.setActualLayout, isSelected = _a.isSelected;
    var tableName = 'CustomerCreatedAiAmazon';
    var visibleState = get(tableName);
    var tableColumns = [
        {
            name: 'facility_name',
            visible: _has(visibleState, 'facility_name') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.facility_name : true,
            title: 'Facility',
            sortable: false,
        },
    ];
    var _b = useState(true), hideHardwareAI = _b[0], setHideHardwareAI = _b[1];
    var _c = useState(true), onlyWaitesNew = _c[0], setOnlyWaitesNew = _c[1];
    var _d = useState([]), sort = _d[0], setSort = _d[1];
    var _e = useState(false), isLoading = _e[0], setIsLoading = _e[1];
    var _f = useState([]), data = _f[0], setData = _f[1];
    useEffect(function () {
        setIsLoading(true);
        Endpoints.amazon
            .getDashboardCustomerCreatedActionList({
            query: {
                selected_facility: selectedFacility,
                hide_hardware_ai: +hideHardwareAI,
                only_waites_new: +onlyWaitesNew,
                sort: sort,
                onlyActive: 1,
            },
        })
            .then(function (res) {
            setData((res === null || res === void 0 ? void 0 : res.data) || []);
        })
            .finally(function () { return setIsLoading(false); });
    }, [hideHardwareAI, onlyWaitesNew, selectedFacility, refreshFlag]);
    return (_jsxs(_Fragment, { children: [isSelected && (_jsxs(_Fragment, { children: [_jsx("div", { className: "draggable-handle" }), _jsx("div", { className: "hide-handle", onClick: function () { return onToggleHandle('hide', 'CustomerCreatedAi', setActualLayout); } })] })), _jsx(CustomerCreatedAi, { title: "Action Items - Customer Created", tableName: tableName, tableColumns: tableColumns, data: data, isLoading: isLoading, systemType: SYSTEM_TYPE_AMAZON, onChangeHideHardwareAI: setHideHardwareAI, onChangeSort: setSort, filters: _jsxs(Label, { check: true, className: "ms-4 me-2", children: [_jsx(Input, { type: "checkbox", defaultChecked: true, onChange: function () { return setOnlyWaitesNew(!onlyWaitesNew); } }), _jsx("span", { children: "WAITES NEW" })] }) })] }));
};
export default CustomerCreatedAiCardWrapper;
