import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useRef, useState } from 'react';
import { ProcessingIncreaseSeverityModal, } from '@sharedModals/AIPreAi/ProcessingIncreaseSeverityModal';
import { IncreasedSeverityAi, } from '../../../../shared/components/dashboard/cards/IncreasedSeverityAi';
import { onToggleHandle } from '../../../../shared/features/dashboard';
import { get } from '../../../../storage/tableColumn';
import { useIncreasedSeverityAIsQuery } from '../../../services/actionItems/useIncreasedSeverityActionListQuery';
var IncreasedSeverityAiCardWrapper = function (_a) {
    var _b;
    var selectedFacility = _a.selectedFacility, setActualLayout = _a.setActualLayout, isSelected = _a.isSelected;
    var tableName = 'IncreasedSeverityAiAmazon';
    var visibleState = get(tableName);
    var tableColumns = [
        {
            name: 'facility_name',
            visible: (_b = visibleState === null || visibleState === void 0 ? void 0 : visibleState.facility_name) !== null && _b !== void 0 ? _b : true,
            title: 'Facility',
            sortable: false,
        },
    ];
    var _c = useState(true), hideHardwareAI = _c[0], setHideHardwareAI = _c[1];
    var _d = useIncreasedSeverityAIsQuery({
        hideHardwareAI: hideHardwareAI,
        selectedFacility: selectedFacility,
    }), _e = _d.data, data = _e === void 0 ? [] : _e, isFetching = _d.isFetching, refetchAIs = _d.refetch;
    var processingIncreaseSeverityModalRef = useRef({});
    var handleRemoveAI = function (_a) {
        var _b, _c;
        var id = _a.id;
        (_c = (_b = processingIncreaseSeverityModalRef.current).deleteSeverityIncrease) === null || _c === void 0 ? void 0 : _c.call(_b, {
            isPreAI: false,
            itemId: id,
            isVisibleModal: true,
            storage: null,
        }).then(function (ok) {
            if (ok)
                refetchAIs();
        });
    };
    return (_jsxs(_Fragment, { children: [Boolean(isSelected) && (_jsxs(_Fragment, { children: [_jsx("div", { className: "draggable-handle" }), _jsx("div", { className: "hide-handle", onClick: function () { return onToggleHandle('hide', 'IncreasedSeverityAi', setActualLayout); } })] })), _jsx(IncreasedSeverityAi, { data: data, tableName: tableName, tableColumns: tableColumns, isLoading: isFetching, onRemoveAI: handleRemoveAI, onChangeHideHardwareAI: setHideHardwareAI }), _jsx(ProcessingIncreaseSeverityModal, { ref: processingIncreaseSeverityModalRef })] }));
};
export default memo(IncreasedSeverityAiCardWrapper);
