var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Api from '../../api';
export default {
    getAlertConditionLog: function (installationPointId, readingTypeId, options) {
        if (options === void 0) { options = {}; }
        return Api.get("amazon-analytic/alert-condition/get-log/".concat(installationPointId, "/").concat(readingTypeId), options);
    },
    getAlertConditionComments: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('amazon-analytic/alert-condition/get-comments', options);
    },
    getAlertConditionCommentsBatch: function (installationPointId, options) {
        if (options === void 0) { options = {}; }
        return Api.get("amazon-analytic/alert-condition/get-comments-batch/".concat(installationPointId), options);
    },
    deleteAlertConditionComment: function (id, options) {
        if (options === void 0) { options = {}; }
        return Api.post("amazon-analytic/alert-condition/delete-comment/".concat(id), options);
    },
    /** @deprecated */
    saveAlertConditions: function (installationPointId, readingTypeId, data) {
        if (data === void 0) { data = {}; }
        return Api.post("amazon-analytic/alert-condition/save/".concat(installationPointId, "/").concat(readingTypeId), data);
    },
    saveAlertConditionsComment: function (data) {
        if (data === void 0) { data = {}; }
        return Api.post('amazon-analytic/alert-condition/save-comment', data);
    },
    createFftAlertCondition: function (installationPointId, data) {
        if (data === void 0) { data = {}; }
        return Api.post("amazon-analytic/alert-conditions/create-fft/".concat(installationPointId), data);
    },
    updateFftAlertCondition: function (data) {
        if (data === void 0) { data = {}; }
        return Api.post('amazon-analytic/alert-conditions/update-fft', data);
    },
    deleteAlertCondition: function (data) {
        if (data === void 0) { data = {}; }
        return Api.post('amazon-analytic/alert-conditions/delete', data);
    },
    createOnceAlertLevel: function (payload, options) {
        var installationPointId = payload.installationPointId, readingTypeId = payload.readingTypeId, levels = payload.levels, overlayInstallationPoints = payload.overlayInstallationPoints, setForAllEquipment = payload.setForAllEquipment, setForAllComponents = payload.setForAllComponents, selected_facility_metric = payload.selected_facility_metric, selectedReadingTypeIds = payload.selectedReadingTypeIds;
        return Api.post("amazon-analytic/alert-condition/create/".concat(installationPointId, "/").concat(readingTypeId), __assign(__assign({}, levels), { overlayInstallationPoints: overlayInstallationPoints, setForAllEquipment: setForAllEquipment, setForAllComponents: setForAllComponents, selected_facility_metric: selected_facility_metric, selectedReadingTypeIds: selectedReadingTypeIds }), options);
    },
    updateOnceAlertLevel: function (payload, options) {
        var levels = payload.levels, overlayInstallationPoints = payload.overlayInstallationPoints, setForAllEquipment = payload.setForAllEquipment, setForAllComponents = payload.setForAllComponents, alertLevelId = payload.alertLevelId, selected_facility_metric = payload.selected_facility_metric, selectedReadingTypeIds = payload.selectedReadingTypeIds;
        return Api.put("amazon-analytic/alert-condition/update/".concat(alertLevelId), __assign(__assign({}, levels), { overlayInstallationPoints: overlayInstallationPoints, setForAllEquipment: setForAllEquipment, setForAllComponents: setForAllComponents, selected_facility_metric: selected_facility_metric, selectedReadingTypeIds: selectedReadingTypeIds }), options);
    },
    deletePairAlertLevels: function (_a, options) {
        var alertPairId = _a.alertPairId;
        return Api.del("amazon-analytic/alert-condition/delete/".concat(alertPairId), {}, options);
    },
    getAlertLevels: function (installationPointId, readingTypeId, selected_facility_metric) {
        return Api.get("amazon-analytic/alert-conditions/".concat(installationPointId, "/").concat(readingTypeId), {
            query: {
                selected_facility_metric: selected_facility_metric,
            },
        });
    },
};
