var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import cx from 'clsx';
import { head as _head } from 'lodash';
import { memo, useEffect } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { FaCheckCircle, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useConfirmModalActions } from '@sharedStore/global/useConfirmModalStore';
import Loader from '../../../../../../../../shared/components/Loader';
import { getComponentType, getEntryBoxNumber, getIsViewMode, getStepName } from '../../../../features';
import { notFirstAssetBuilderSchema } from '../../../../schemas/notFirstAssetBuilderSchema';
import { useAssetModuleQuery } from '../../../../services/useAssetModuleQuery';
import { useAssetTreeQuery } from '../../../../services/useAssetTreeQuery';
import { useAssetCreateMutation } from '../../../../services/useCreateAssetMutation';
import { useUnassignedPointsQuery } from '../../../../services/useUnassignedPointsQuery';
import { selectComponentId } from '../../../../stores/useAssetBuilderStore';
import assetBuilderStyles from '../../styles.module.scss';
import { BackToStep } from '../BackToStep';
import { Title } from '../Title';
import { DrivenContent } from './components/DrivenContent';
import { FoundationContent } from './components/FoundationContent';
import { GearboxContent } from './components/GearboxContent';
import { FIRST_STEP_TO_DEFAULT_VALUES, SECOND_STEP_TO_DEFAULT_VALUES } from './constants';
import { getDrivenBody, getFoundationBody, getGearboxBody } from './features';
export var NotFirstAssetContent = memo(function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j;
    var equipmentId = _a.equipmentId;
    var _k = useAssetCreateMutation({ equipmentId: equipmentId }), notFirstAssetCreateMutate = _k.mutate, isPending = _k.isPending;
    var assetTreeData = useAssetTreeQuery({ equipmentId: equipmentId }).data;
    var unassignedPointsData = useUnassignedPointsQuery({ equipmentId: equipmentId }).data;
    var assetModuleData = useAssetModuleQuery().data;
    var hasCompactMachine = Boolean((_b = _head(assetTreeData === null || assetTreeData === void 0 ? void 0 : assetTreeData.data)) === null || _b === void 0 ? void 0 : _b.assetConfigs.find(function (_a) {
        var assetModuleId = _a.assetModuleId;
        return assetModuleId === 6;
    }));
    var componentId = selectComponentId();
    var isViewMode = getIsViewMode(assetTreeData === null || assetTreeData === void 0 ? void 0 : assetTreeData.data);
    var componentData = isViewMode
        ? (_c = _head(assetTreeData === null || assetTreeData === void 0 ? void 0 : assetTreeData.data)) === null || _c === void 0 ? void 0 : _c.assetConfigs.find(function (_a) {
            var id = _a.id;
            return id === componentId;
        })
        : null;
    var noIndex = ((_d = _head(assetTreeData === null || assetTreeData === void 0 ? void 0 : assetTreeData.data)) === null || _d === void 0 ? void 0 : _d.assetConfigs.findIndex(function (_a) {
        var id = _a.id;
        return id === componentId;
    })) === -1;
    // @ts-ignore
    var calculatedData = noIndex
        ? (_f = (_e = _head(assetTreeData === null || assetTreeData === void 0 ? void 0 : assetTreeData.data)) === null || _e === void 0 ? void 0 : _e.assetConfigs) !== null && _f !== void 0 ? _f : []
        : ((_h = (_g = _head(assetTreeData === null || assetTreeData === void 0 ? void 0 : assetTreeData.data)) === null || _g === void 0 ? void 0 : _g.assetConfigs) !== null && _h !== void 0 ? _h : []).slice(0, (_j = _head(assetTreeData === null || assetTreeData === void 0 ? void 0 : assetTreeData.data)) === null || _j === void 0 ? void 0 : _j.assetConfigs.findIndex(function (_a) {
            var id = _a.id;
            return id === componentId;
        }));
    var entryBoxNumber = getEntryBoxNumber(calculatedData !== null && calculatedData !== void 0 ? calculatedData : []);
    var formData = useForm({
        defaultValues: {
            isGearbox: false,
            data: __assign({ step: 1 }, FIRST_STEP_TO_DEFAULT_VALUES),
        },
        resolver: notFirstAssetBuilderSchema(entryBoxNumber),
    });
    var setValue = formData.setValue, trigger = formData.trigger, control = formData.control, reset = formData.reset, errors = formData.formState.errors;
    console.log(errors);
    var _l = useWatch({
        control: control,
        name: ['isGearbox', 'data'],
    }), isGearbox = _l[0], data = _l[1];
    var step = data.step, isDriven = data.isDriven;
    useEffect(function () {
        if (!isViewMode || !componentData) {
            return;
        }
        reset();
        setTimeout(function () {
            var _a;
            setValue('isGearbox', (componentData === null || componentData === void 0 ? void 0 : componentData.assetModuleId) === 3);
            setValue('data', ((_a = componentData === null || componentData === void 0 ? void 0 : componentData.options) === null || _a === void 0 ? void 0 : _a.fullData)
                ? __assign(__assign({}, componentData.options.fullData), { step: 8 }) : __assign(__assign({}, FIRST_STEP_TO_DEFAULT_VALUES), { step: 8 }));
        });
    }, [isViewMode, componentData]);
    var setConfirmData = useConfirmModalActions().setConfirmData;
    var onSave = function () { return __awaiter(void 0, void 0, void 0, function () {
        var isValid, callback;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, trigger()];
                case 1:
                    isValid = _a.sent();
                    if (!isValid) {
                        return [2 /*return*/];
                    }
                    callback = function () {
                        var _a, _b, _c, _d, _e, _f;
                        return notFirstAssetCreateMutate(isGearbox
                            ? getGearboxBody(data, (_a = assetTreeData === null || assetTreeData === void 0 ? void 0 : assetTreeData.data) !== null && _a !== void 0 ? _a : [], (_b = assetModuleData === null || assetModuleData === void 0 ? void 0 : assetModuleData.data) !== null && _b !== void 0 ? _b : [])
                            : isDriven
                                ? getDrivenBody(data, (_c = assetTreeData === null || assetTreeData === void 0 ? void 0 : assetTreeData.data) !== null && _c !== void 0 ? _c : [], (_d = assetModuleData === null || assetModuleData === void 0 ? void 0 : assetModuleData.data) !== null && _d !== void 0 ? _d : [])
                                : getFoundationBody(data, (_e = assetTreeData === null || assetTreeData === void 0 ? void 0 : assetTreeData.data) !== null && _e !== void 0 ? _e : [], (_f = assetModuleData === null || assetModuleData === void 0 ? void 0 : assetModuleData.data) !== null && _f !== void 0 ? _f : []), {
                            onSuccess: function () {
                                toast.success('Successfully created new component', {
                                    icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                                });
                            },
                        });
                    };
                    if (step === (isGearbox ? 7 : isDriven ? 5 : 3)) {
                        if (step === 3 && !isGearbox && !isDriven && !!(unassignedPointsData === null || unassignedPointsData === void 0 ? void 0 : unassignedPointsData.data)) {
                            return [2 /*return*/, setConfirmData({
                                    isVisible: true,
                                    textConfirmationModal: 'You still have not assigned IPs, are you sure you want to create the asset?',
                                    onConfirm: function () {
                                        setConfirmData({
                                            isVisible: false,
                                        });
                                        callback();
                                    },
                                })];
                        }
                        return [2 /*return*/, callback()];
                    }
                    setValue('data', __assign(__assign({}, data), { step: step + 1 }));
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(FormProvider, __assign({}, formData, { children: [_jsx(Title, { isViewMode: isViewMode, step: step, stepName: getStepName(step, getComponentType(step, isGearbox, isDriven)) }), _jsxs("div", { className: assetBuilderStyles.content, children: [_jsxs("div", { className: assetBuilderStyles.groupBlock, children: [_jsx("div", { className: assetBuilderStyles.sectionsBlock, children: _jsx("div", { className: assetBuilderStyles.section, children: _jsxs("label", { htmlFor: "isGearbox", className: assetBuilderStyles.switchWrapper, children: [_jsxs("div", { className: assetBuilderStyles.switch, onClick: function () {
                                                    if (!(unassignedPointsData === null || unassignedPointsData === void 0 ? void 0 : unassignedPointsData.data)) {
                                                        toast.error('Not assigned IP is empty', {
                                                            icon: _jsx(FaTimes, { style: { fill: '#198754' } }),
                                                        });
                                                    }
                                                    if (hasCompactMachine) {
                                                        toast.error('Asset has Compact Machine!', {
                                                            icon: _jsx(FaTimes, { style: { fill: '#198754' } }),
                                                        });
                                                    }
                                                }, children: [_jsx("input", { disabled: step !== 1 || !(unassignedPointsData === null || unassignedPointsData === void 0 ? void 0 : unassignedPointsData.data) || hasCompactMachine, type: "checkbox", id: "isGearbox", checked: isGearbox, onChange: function (e) { return setValue('isGearbox', e.target.checked); } }), _jsx("span", { className: assetBuilderStyles.slider })] }), "Do you want to add Gearbox to this equipment?"] }) }) }), _jsx(BackToStep, { isViewMode: isViewMode, callback: function () { return setValue('data', __assign(__assign(__assign({}, data), { step: 1 }), FIRST_STEP_TO_DEFAULT_VALUES)); }, isShow: step !== 1 })] }), step >= 2 && (_jsx(_Fragment, { children: (isViewMode ? (componentData === null || componentData === void 0 ? void 0 : componentData.assetModuleId) === 3 : isGearbox) ? (_jsx(GearboxContent, { control: control, step: step, equipmentId: equipmentId, entryBoxNumber: entryBoxNumber })) : (_jsxs(_Fragment, { children: [_jsxs("div", { className: assetBuilderStyles.groupBlock, children: [_jsx("div", { className: assetBuilderStyles.sectionsBlock, children: _jsx("div", { className: assetBuilderStyles.section, children: _jsxs("label", { htmlFor: "isDriven", className: assetBuilderStyles.switchWrapper, children: [_jsxs("div", { className: assetBuilderStyles.switch, onClick: function () {
                                                                if (!(unassignedPointsData === null || unassignedPointsData === void 0 ? void 0 : unassignedPointsData.data)) {
                                                                    toast.error('Not assigned IP is empty', {
                                                                        icon: _jsx(FaTimes, { style: { fill: '#198754' } }),
                                                                    });
                                                                }
                                                                if (hasCompactMachine) {
                                                                    toast.error('Asset has Compact Machine!', {
                                                                        icon: _jsx(FaTimes, { style: { fill: '#198754' } }),
                                                                    });
                                                                }
                                                            }, children: [_jsx("input", { disabled: step !== 2 || !(unassignedPointsData === null || unassignedPointsData === void 0 ? void 0 : unassignedPointsData.data) || hasCompactMachine, type: "checkbox", id: "isDriven", checked: isDriven, onChange: function (e) { return setValue('data.isDriven', e.target.checked); } }), _jsx("span", { className: assetBuilderStyles.slider })] }), "Do you want to add another component to this equipment?"] }) }) }), _jsx(BackToStep, { isViewMode: isViewMode, callback: function () { return setValue('data', __assign(__assign(__assign({}, data), { step: 2 }), SECOND_STEP_TO_DEFAULT_VALUES)); }, isShow: step !== 2 })] }), step >= 3 && (_jsx(_Fragment, { children: (isViewMode ? (componentData === null || componentData === void 0 ? void 0 : componentData.assetModuleId) === 4 : isDriven) ? (_jsx(DrivenContent, { control: control, step: step, equipmentId: equipmentId, entryBoxNumber: entryBoxNumber })) : (_jsx("div", { className: assetBuilderStyles.groupBlock, children: _jsx("div", { className: assetBuilderStyles.sectionsBlock, children: _jsx(FoundationContent, { isDisabled: step !== 3 }) }) })) }))] })) })), !isViewMode && (_jsx("div", { className: assetBuilderStyles.groupBlock, children: _jsx("div", { className: cx(assetBuilderStyles.sectionsBlock, assetBuilderStyles.full), children: _jsx("div", { className: assetBuilderStyles.section, children: _jsx("button", { className: assetBuilderStyles.next, onClick: onSave, children: isPending ? (_jsx(Loader, { variant: 'loader-exs' })) : (_jsx(_Fragment, { children: step === (isGearbox ? 7 : isDriven ? 5 : 3) ? 'Save' : 'Next' })) }) }) }) }))] })] })));
});
NotFirstAssetContent.displayName = 'NotFirstAssetContent';
