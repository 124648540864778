var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Label } from 'reactstrap';
import { PRE_ACTION_LIST, READING_TYPES, READING_TYPE_ACCELERATION_X, READING_TYPE_PK_PK_X, READING_TYPE_TEMPERATURE, READING_TYPE_VELOCITY_X, } from '../../../constants';
import { DateTimeLabel } from '../../../widgets/common';
import { PreStatusLabel, PreUrgencyLabel } from '../../../widgets/preActionItems';
import { SensorId } from '../../../widgets/sensor';
import ActivityIndicator from '../../components/AIPreAi/ActivityIndicator';
import Filters from '../../components/preActionItem/Filter/Filter';
export var config = function (extendedColumns, isVisibleTechPreAi) {
    if (isVisibleTechPreAi === void 0) { isVisibleTechPreAi = true; }
    return ({
        columns: __spreadArray(__spreadArray([
            {
                name: 'watching_filter',
                titleComponent: function (callbacks, filtersData) {
                    var _a = useState(false), isOpen = _a[0], setIsOpen = _a[1];
                    var labelsMapping = {
                        not_watched: 'Not viewed',
                        watching: 'Watching',
                        watched: 'Processed',
                    };
                    var field = filtersData.watchingFilter;
                    return (_jsxs(Dropdown, { direction: "down", isOpen: isOpen, toggle: function () { return setIsOpen(!isOpen); }, children: [_jsx(DropdownToggle, { size: "sm", caret: true, tag: "span", children: labelsMapping[field] || 'All' }), _jsxs(DropdownMenu, { className: "dropdown-menu", children: [_jsx(DropdownItem, { onClick: function () { return callbacks.onChangeWatchingFilter('not_watched'); }, children: _jsxs(Label, { check: true, className: "ms-2", children: [_jsx(Input, { type: "checkbox", defaultChecked: filtersData.watchingFilter === 'not_watched' }), "Not viewed"] }) }), _jsx(DropdownItem, { onClick: function () { return callbacks.onChangeWatchingFilter('watching'); }, children: _jsxs(Label, { check: true, className: "ms-2", children: [_jsx(Input, { type: "checkbox", defaultChecked: filtersData.watchingFilter === 'watching' }), "Watching"] }) }), _jsx(DropdownItem, { onClick: function () { return callbacks.onChangeWatchingFilter('watched'); }, children: _jsxs(Label, { check: true, className: "ms-2", children: [_jsx(Input, { type: "checkbox", defaultChecked: filtersData.watchingFilter === 'watched', onChange: function () { return callbacks.onChangeWatchingFilter('watched'); } }), "Processed"] }) })] })] }));
                },
                component: function (row) {
                    return _jsx(ActivityIndicator, { className: "mt-2 ms-2", analystLog: row.preActionListAnalystLog });
                },
                sortable: false,
            }
        ], extendedColumns, true), [
            {
                name: 'equipment_name',
                title: 'Equipment',
                sortable: true,
                component: function (row, callbacks) {
                    return (_jsx(_Fragment, { children: row.newPreAisOnEquipmentCount ? (_jsx("span", { onClick: function () { return callbacks.viewEquipmentPreAi(+row.installation_point_id, row === null || row === void 0 ? void 0 : row.equipment_id); }, className: 'me-2 fake-link', children: row.equipment_name })) : (_jsx("span", { className: 'me-2', children: row.equipment_name })) }));
                },
            },
            {
                name: 'point_name',
                title: 'Point name',
                sortable: true,
            },
            {
                name: 'sensor_id',
                title: 'Sensor ID',
                sortable: true,
                component: function (row) {
                    return _jsx(SensorId, { hexBreak: true, sensor_id: +row.sensor_id });
                },
            },
            {
                name: 'created_at',
                title: 'Date created',
                sortable: true,
                component: function (row) {
                    return (_jsx(DateTimeLabel, { id: "pre_ai_list_created_at_".concat(row.id), dateTime: row.created_at, timeBreak: true }));
                },
            },
            {
                name: 'user_name',
                title: 'Added by',
                sortable: true,
            },
            {
                name: 'action_user_name',
                title: 'Processed by',
                sortable: true,
            },
            {
                name: 'updated_at',
                title: 'Last Update',
                sortable: true,
                component: function (row) {
                    return (_jsx(DateTimeLabel, { id: "pre_ai_list_updated_at_".concat(row.id), dateTime: row.updated_at, timeBreak: true }));
                },
            },
            {
                name: 'sla',
                title: 'SLA',
                sortable: true,
                component: function (row) {
                    return row.sla || parseInt(row.sla) === 0 ? row.sla : '---';
                },
            },
            {
                name: 'priority',
                title: 'Priority',
                sortable: true,
                component: function (row) {
                    return row.priority === null ? '---' : row.priority;
                },
            },
            {
                name: 'readingTypes',
                titleComponent: function (callbacks, filtersData) {
                    var _a = useState(false), isOpen = _a[0], setIsOpen = _a[1];
                    return (_jsxs(Dropdown, { direction: "down", isOpen: isOpen, toggle: function () { return setIsOpen(!isOpen); }, children: [_jsx(DropdownToggle, { size: "sm", caret: true, tag: "span", children: filtersData.readingType
                                    ? _get(READING_TYPES, [filtersData.readingType, 'title'], '')
                                    : 'Reading Types' }), _jsxs(DropdownMenu, { className: "dropdown-menu", style: { marginLeft: '-30px' }, children: [_jsx(DropdownItem, { onClick: function () { return callbacks.onChangeReadingTypeFilter(READING_TYPE_TEMPERATURE); }, children: _jsxs(Label, { check: true, className: "ms-2", children: [_jsx(Input, { type: "checkbox", defaultChecked: filtersData.readingType === READING_TYPE_TEMPERATURE }), "Temperature"] }) }), _jsx(DropdownItem, { onClick: function () { return callbacks.onChangeReadingTypeFilter(READING_TYPE_ACCELERATION_X); }, children: _jsxs(Label, { check: true, className: "ms-2", children: [_jsx(Input, { type: "checkbox", defaultChecked: filtersData.readingType === READING_TYPE_ACCELERATION_X }), "Acceleration"] }) }), _jsx(DropdownItem, { onClick: function () { return callbacks.onChangeReadingTypeFilter(READING_TYPE_VELOCITY_X); }, children: _jsxs(Label, { check: true, className: "ms-2", children: [_jsx(Input, { type: "checkbox", defaultChecked: filtersData.readingType === READING_TYPE_VELOCITY_X }), "Velocity"] }) }), _jsx(DropdownItem, { onClick: function () { return callbacks.onChangeReadingTypeFilter(READING_TYPE_PK_PK_X); }, children: _jsxs(Label, { check: true, className: "ms-2", children: [_jsx(Input, { type: "checkbox", defaultChecked: filtersData.readingType === READING_TYPE_PK_PK_X }), "PK-PK"] }) })] })] }));
                },
                sortable: false,
            },
            {
                name: 'urgency_filter',
                titleComponent: function (callbacks, filtersData) {
                    var _a = useState(false), isOpen = _a[0], setIsOpen = _a[1];
                    var labelsMapping = {
                        '1': 'Standard',
                        '2': 'High',
                        '3': 'Critical',
                    };
                    var field = filtersData.urgency;
                    return (_jsxs(Dropdown, { direction: "down", isOpen: isOpen, toggle: function () { return setIsOpen(!isOpen); }, children: [_jsx(DropdownToggle, { size: "sm", caret: true, tag: "span", children: labelsMapping[field] || 'Urgency' }), _jsx(DropdownMenu, { className: "dropdown-menu", style: { marginLeft: '-30px' }, children: _jsx("div", { children: Object.values(labelsMapping).map(function (label, index) { return (_jsx(DropdownItem, { onClick: function () { return callbacks.onChangeUrgencyFilter(Object.keys(labelsMapping)[index]); }, children: _jsxs(Label, { check: true, className: "ms-2", children: [_jsx(Input, { type: "checkbox", defaultChecked: filtersData.urgency === Object.keys(labelsMapping)[index] }), label] }) }, "urgency_title_filter_".concat(label))); }) }) })] }));
                },
                sortable: false,
                component: function (row) {
                    return _jsx(PreUrgencyLabel, { urgency: +row.urgency });
                },
            },
            {
                title: 'Status',
                name: 'status',
                sortable: true,
                component: function (row) {
                    return _jsx(PreStatusLabel, { status: +row.status, isTech: row.source === 'installapp' });
                },
            },
        ], false),
        pagination: {
            page: 1,
            pageSize: 20,
            total: 0,
        },
        sort: [
            {
                field: 'updated_at',
                direction: 'desc',
            },
        ],
        search: '',
        data: [],
        loader: true,
        filtersData: {
            watchingFilter: '',
            statuses: PRE_ACTION_LIST.STATUSES,
            dateRange: [],
            selectedStatuses: [],
            users: [],
            selectedUserId: '',
            urgency: '',
            readingType: null,
            userActivityStatuses: PRE_ACTION_LIST.USER_ACTIVITY_STATUSES,
            stats: {},
        },
        filters: function (callbacks, filtersData) {
            return (_jsx(Filters, { onTechPreAi: callbacks.onTechPreAi, onChangeMLReportStatus: callbacks.onChangeMLReportStatus, onIncreaseSeverity: callbacks.onIncreaseSeverity, onChangeUserStatus: callbacks.onChangeUserStatus, onChangeAutoFilter: callbacks.onChangeAutoFilter, onChangeStatus: callbacks.onChangeStatus, onChangeUser: callbacks.onChangeUser, onChangeDateRange: callbacks.onChangeDateRange, dateRange: filtersData.dateRange, users: filtersData.users, statuses: filtersData.statuses, selectedUser: filtersData.selectedUserId, selectedStatuses: filtersData.selectedStatuses, userActivityStatuses: filtersData.userActivityStatuses, stats: filtersData.stats, isVisibleTechPreAi: isVisibleTechPreAi }));
        },
    });
};
