var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useInfiniteQuery as useUntypedInfiniteQuery } from '@tanstack/react-query';
import { head as _head } from 'lodash';
import Api from '../../../api/api';
var useInfiniteQuery = useUntypedInfiniteQuery;
var PAGE_SIZE = 20;
export var customerCreatedQueryKey = function (payload) { return [
    'customerCreatedAI',
    payload,
]; };
var customerCreatedQueryFn = function (payload) { return __awaiter(void 0, void 0, Promise, function () {
    var hideHardwareAI, selectedCustomers, selectedLocations, sort, pageParam, onlyCustomerRequest;
    return __generator(this, function (_a) {
        hideHardwareAI = payload.hideHardwareAI, selectedCustomers = payload.selectedCustomers, selectedLocations = payload.selectedLocations, sort = payload.sort, pageParam = payload.pageParam, onlyCustomerRequest = payload.onlyCustomerRequest;
        return [2 /*return*/, Api.get('api/analytic/action-item/list/customer-created', {
                host: APP_API_V2_URL,
                query: {
                    selected_customer_ids: selectedCustomers,
                    selected_location_id: _head(selectedLocations),
                    hide_hardware_ai: +hideHardwareAI,
                    is_customer_request: +onlyCustomerRequest,
                    sort: sort,
                    onlyActive: 1,
                    pagination: {
                        pageSize: PAGE_SIZE,
                        page: pageParam,
                    },
                },
            })];
    });
}); };
export var useCustomerCreatedInfinityQuery = function (_a) {
    var hideHardwareAI = _a.hideHardwareAI, selectedCustomers = _a.selectedCustomers, selectedLocations = _a.selectedLocations, sort = _a.sort, onlyCustomerRequest = _a.onlyCustomerRequest;
    var queryKey = customerCreatedQueryKey({
        hideHardwareAI: hideHardwareAI,
        selectedCustomers: selectedCustomers,
        selectedLocations: selectedLocations,
        sort: sort,
        onlyCustomerRequest: onlyCustomerRequest,
    });
    return useInfiniteQuery({
        queryKey: queryKey,
        queryFn: function (_a) {
            var pageParam = _a.pageParam;
            return customerCreatedQueryFn({
                hideHardwareAI: +hideHardwareAI,
                pageParam: pageParam,
                selectedCustomers: selectedCustomers,
                selectedLocations: selectedLocations,
                sort: sort,
                onlyCustomerRequest: onlyCustomerRequest,
            });
        },
        initialPageParam: 1,
        getNextPageParam: function (lastPage, _, lastPageParam) {
            return (lastPage === null || lastPage === void 0 ? void 0 : lastPage.meta.onLastPage) ? null : lastPageParam + 1;
        },
        select: function (_a) {
            var pages = _a.pages;
            return pages.map(function (_a) {
                var data = _a.data;
                return data;
            }).flat();
        },
    });
};
