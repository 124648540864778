var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { get as _get, size as _size } from 'lodash';
import React, { Fragment, useMemo, useState } from 'react';
import { BsFillPinAngleFill, BsFillPinFill } from 'react-icons/bs';
import { FaCheck, FaTachometerAlt } from 'react-icons/fa';
import { Col, Row } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import { abortOverlayRequests } from '../../../features/charts/leftEquipmentList';
import { InstallationPointStore } from '../../../store/charts/chartsContent/InstallationPointStore';
import { InListStore } from '../../../store/charts/inList/InListStore';
import { LeftEquipmentListStore } from '../../../store/charts/leftEquipmentList/LeftEquipmentListStore';
import HighlighterWord from '../../shared/HighlighterWord';
import ComponentRow from './ComponentRow';
import RowStatus from './RowStatus';
var EquipmentRow = function (_a) {
    var _b, _c;
    var equipment = _a.equipment, index = _a.index, onAllInstallationPointsOverlayUncheck = _a.onAllInstallationPointsOverlayUncheck, onOverlayCheck = _a.onOverlayCheck, equipmentTitle = _a.equipmentTitle, equipmentName = _a.equipmentName, setFilter = _a.setFilter;
    var inList = InListStore(function (state) { return ({
        inList: state.inList,
    }); }, shallow).inList;
    var _d = LeftEquipmentListStore(function (state) { return ({
        filter: state.filter,
        toggleExpand: state.toggleExpand,
        equipmentIsExpanded: state.equipmentIsExpanded,
    }); }, shallow), filter = _d.filter, toggleExpand = _d.toggleExpand, equipmentIsExpanded = _d.equipmentIsExpanded;
    var _e = InstallationPointStore(function (state) { return ({
        setStatesInstallationPointStore: state.setStates,
        installationPoint: state.installationPoint,
        overlayInstallationPoints: state.overlayInstallationPoints,
    }); }, shallow), setStatesInstallationPointStore = _e.setStatesInstallationPointStore, installationPoint = _e.installationPoint, overlayInstallationPoints = _e.overlayInstallationPoints;
    var groupedByComponentInstallationPoints = useMemo(function () {
        return equipment.installationPoints.reduce(function (groups, installationPoint) {
            var component = installationPoint.component;
            component = component || 'Not grouped';
            if (!groups[component]) {
                groups[component] = [];
            }
            groups[component].push(installationPoint);
            return groups;
        }, {});
    }, [equipment.installationPoints]);
    var _f = useState(index === 0), isActive = _f[0], setIsActive = _f[1];
    var selectedEquipment = +equipment.id === +installationPoint.equipment_id;
    var mainEquipment = useMemo(function () {
        return (filter.installation_point &&
            _size(equipment.installationPoints.filter(function (installationPoint) { return +installationPoint.id === +_get(filter, 'installation_point'); })) > 0);
    }, [filter.installation_point]);
    var overlayedInstallationPointsOnEquipment = useMemo(function () {
        if (overlayInstallationPoints.length === 0) {
            return [];
        }
        var overlayInstallationPointsIds = overlayInstallationPoints.map(function (overlayInstallationPoint) { return +overlayInstallationPoint.id; });
        return equipment.installationPoints.filter(function (installationPoint) {
            return overlayInstallationPointsIds.includes(+installationPoint.id);
        });
    }, [overlayInstallationPoints]);
    var toggleEquipmentBlock = function (ev) {
        toggleExpand(null);
        if (ev.target.type === 'checkbox' ||
            ['svg', 'path'].includes(ev.target.tagName) ||
            ['equipment-item-head-lock'].includes(ev.target.className)) {
            return;
        }
        setIsActive(index === 0 ? true : !isActive);
    };
    useMemo(function () {
        if (equipmentIsExpanded === null) {
            return;
        }
        setIsActive(index === 0 ? true : equipmentIsExpanded);
    }, [equipmentIsExpanded]);
    var onAllInstallationPointsOverlay = function (ev, equipment) {
        ev.preventDefault();
        ev.stopPropagation();
        abortOverlayRequests();
        var changedOverlayInstallationPoints = __spreadArray([], overlayInstallationPoints, true);
        equipment.installationPoints.forEach(function (installationPointEquipment) {
            changedOverlayInstallationPoints.push(__assign(__assign({}, installationPointEquipment), { id: +installationPointEquipment.id }));
        });
        setStatesInstallationPointStore({ overlayInstallationPoints: changedOverlayInstallationPoints });
    };
    return (_jsxs("div", { className: "equipment-item", children: [_jsxs("div", { className: "equipment-item-head", onClick: function (ev) { return toggleEquipmentBlock(ev); }, children: [_jsx(RowStatus, { isInstallationPoint: false, installationPoints: equipment.installationPoints, inList: inList }), _jsxs(Row, { children: [_jsxs(Col, { md: 9, className: mainEquipment ? 'text-primary' : '', children: [_jsxs("div", { className: "d-block", children: [equipmentTitle(equipment).length > 0 && (_jsx("div", { className: "equipment-title", children: _jsx(HighlighterWord, { searchWords: filter.search, block: false, textToHighlight: equipmentTitle(equipment) }) })), _jsx(HighlighterWord, { searchWords: filter.search, block: false, textToHighlight: equipment.name })] }), equipmentName(equipment), !!equipment.tachometer && (_jsxs("div", { className: "d-flex align-items-center justify-content-between equipment-code", children: ["Tachometer: ", _jsx(FaTachometerAlt, { style: { marginRight: '-20%' }, color: "#999" })] }))] }), _jsxs(Col, { md: 3, className: "d-flex justify-content-between", children: [mainEquipment ? (_jsx("div", { className: "equipment-item-head-lock", children: _jsx(BsFillPinFill, {}) })) : selectedEquipment ? (_jsx("div", { className: "equipment-item-head-lock", onClick: function () {
                                            setFilter('installation_point', +installationPoint.id);
                                        }, children: _jsx(BsFillPinAngleFill, {}) })) : (_jsx("div", {})), _jsx("div", { className: "checkbox-all-points ".concat(overlayedInstallationPointsOnEquipment.length === equipment.installationPoints.length
                                            ? 'active'
                                            : ''), onClick: function (ev) {
                                            return overlayedInstallationPointsOnEquipment.length === equipment.installationPoints.length
                                                ? onAllInstallationPointsOverlayUncheck(ev, equipment)
                                                : onAllInstallationPointsOverlay(ev, equipment);
                                        }, children: _jsx(FaCheck, {}) })] })] })] }), _jsx(Row, { className: "g-0 equipment-installation-points-container ".concat(isActive ? 'active-equipment' : ''), children: _jsx(Col, { md: 12, children: ((_b = equipment.assets) === null || _b === void 0 ? void 0 : _b.length) ? (_jsxs(_Fragment, { children: [equipment.assets[0].assetConfigs.map(function (assetConfig, index) { return (_jsx(Fragment, { children: _jsx(ComponentRow, { installationPoints: assetConfig.installationPoints, equipmentName: equipment.name, equipment: equipment, componentName: assetConfig.name, assetConfig: assetConfig, activeInstallationPointId: installationPoint.id, onOverlayCheck: onOverlayCheck, overlayInstallationPoints: overlayInstallationPoints }) }, assetConfig.id + index)); }), ((_c = equipment.unassignedAssetPoints) === null || _c === void 0 ? void 0 : _c.length) && (_jsx(ComponentRow, { installationPoints: equipment.unassignedAssetPoints, equipmentName: 'Not assigned points', equipment: equipment, componentName: 'Not assigned points', activeInstallationPointId: installationPoint.id, onOverlayCheck: onOverlayCheck, overlayInstallationPoints: overlayInstallationPoints, isNotAssigned: true }))] })) : (_jsx(_Fragment, { children: Object.values(groupedByComponentInstallationPoints).map(function (installationPoints, componentIndex) {
                            if (equipment.tachometer) {
                                return _jsx(_Fragment, {});
                            }
                            var componentName = Object.keys(groupedByComponentInstallationPoints)[componentIndex];
                            return (_jsx(Fragment, { children: _jsx(ComponentRow, { installationPoints: installationPoints, equipmentName: equipment.name, equipment: equipment, componentName: componentName, activeInstallationPointId: installationPoint.id, onOverlayCheck: onOverlayCheck, overlayInstallationPoints: overlayInstallationPoints }, "".concat(equipment.id, "_").concat(componentName, "_").concat(_get(installationPoints.filter(function (item) { return item; }), '0.id'))) }, componentIndex));
                        }) })) }) })] }));
};
export default React.memo(EquipmentRow);
