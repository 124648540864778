// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.position__Wy_eR {
  display: grid;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-columns: 1fr 1fr 2fr;
}`, "",{"version":3,"sources":["webpack://./../src/remastered/components/charts/AssetBuilder/components/SetupContent/components/FirstAssetContent/components/DriverAMotor/styles.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,qBAAA;EACA,kBAAA;EACA,kCAAA;AACD","sourcesContent":[".position {\n\tdisplay: grid;\n\tgrid-column-gap: 16px;\n\tgrid-row-gap: 16px;\n\tgrid-template-columns: 1fr 1fr 2fr;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"position": `position__Wy_eR`
};
module.exports = ___CSS_LOADER_EXPORT___;
