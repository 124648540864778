import Api from '../../api';
export default {
    getDashboardSubmittedReportActionList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/action-list/submitted-report', options);
    },
    getDashboardDeclinedReportActionList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/action-list/with-declined-reports', options);
    },
    getDashboardIncreasedSeverityPreActionList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/pre-action-list/increased-severity', options);
    },
    getDashboardWatchList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/watchlist/dashboard', options);
    },
    getDashboardOldPointsList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/installation-point/old-points', options);
    },
    getDashboardPreActionList: function (options) {
        if (options === void 0) { options = {}; }
        return Api.get('all-analytics/pre-action-list/dashboard', options);
    },
};
